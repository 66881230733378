import React from "react";
import Colors from "../../Constants/Colors";
import GiftListRender from "./GiftListRender";

function GiftSessionList({
  data,
  sessionId,
  user,
  astrologer,
  dateTime,
  sessionType,
  duration,
  fees,
  totalfees,
  status,
  commentsContainerRef,
  handleScroll,
}) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        borderRadius: "8px",
        border: Colors.darkBorderColor,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          flex: 1,
          backgroundColor: Colors.borderColor,
          borderBottom: Colors.darkBorderColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem",
          gap: "0.5rem",
        }}
      >
        <div style={{ width: "14%" }} className="contentName">
          {sessionId}
        </div>
        <div style={{ width: "16%" }} className="contentName">
          {user}
        </div>
        <div style={{ width: "16%" }} className="contentName">
          {astrologer}
        </div>
        <div style={{ width: "10%" }} className="contentName">
          {dateTime}
        </div>
        <div style={{ width: "13%" }} className="contentName">
          {sessionType}
        </div>
        <div style={{ width: "7%" }} className="contentName">
          {duration}
        </div>
        <div style={{ width: "7%" }} className="contentName">
          {fees}
        </div>
        <div
          style={{
            width: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {totalfees}
          </div>
        </div>
        <div style={{ width: "7%" }} className="contentName">
          {status}
        </div>
      </div>
      <div
        style={{ maxHeight: "500px" }}
        ref={commentsContainerRef}
        onScroll={handleScroll}
        className="scrollable-container"
      >
        {data.map((session, index) => (
          <GiftListRender key={index} session={session} />
        ))}
      </div>
    </div>
  );
}

export default GiftSessionList;

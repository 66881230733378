import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Icons from "../../../Constants/Icons";
import Header from "../../../Components/Header/Header";
import "./SessionScreenStyle.css";
import SessionList from "../../../Components/SessionList/SessionList";
import { useNavigate } from "react-router-dom";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import {
  api_Get_Session_List,
  get_session_overview_details,
  postSessionGetOverview,
} from "../../../Core/Apicall";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";

function Session() {
  const items = ["Today", "This week", "Last Week", "This Month", "All Time"];
  const statusDropdownItem = [
    "All",
    "Ongoing",
    "Scheduled",
    "Completed",
    "Rejected",
    "Missed",
  ];
  const [searchValue, setSearchValue] = useState("");
  const [callSessionData, setCallSessionData] = useState();
  const [chatSessionData, setChatSessionData] = useState();
  const [liveSessionData, setLiveSessionData] = useState();
  const [sessionOverview, setSessionOverview] = useState();
  const [sessionList, sessionListData] = useState([]);
  const [sessionAllList, sessionListAllData] = useState([]);
  const [sessionPageCount, setTotalSessionPageCount] = useState(0);
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");
  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const DropdownCallsessionHandle = () => {};

  useEffect(() => {
    onApiCallGetSessionOverviewDetails();
    onApiCallGetSessionOverView("call");
    onApiCallGetSessionOverView("chat");
    onApiCallGetSessionOverView("live");
  }, []);

  useEffect(() => {
    setPageComments(0);
    sessionListData([]);
    sessionListAllData([]);
    onApiCallGetSessionList(dropdownSelected, 0);
  }, [dropdownSelected]);

  const onApiCallGetSessionList = (type, page) => {
    let param = {
      offset: 30 * page,
      limit: 30,
    };

    const dropdownSelectedMap = {
      All: "all",
      Ongoing: "ongoing",
      Scheduled: "schedule",
      Completed: "completed",
      Rejected: "declined",
      Missed: "missed",
    };

    const dropdownMethodPass = dropdownSelectedMap[type] || "";

    let data = {
      type: dropdownMethodPass,
    };
    dispatch(setLoadingTrue());
    api_Get_Session_List(param, data)
      .then((response) => {
        setSearchValue("");
        sessionListData((prevData) => [...prevData, ...response?.sessionlist]);
        sessionListAllData((prevData) => [
          ...prevData,
          ...response?.sessionlist,
        ]);
        setTotalSessionPageCount(Math.ceil(response.totalSessionsCount / 30));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleScroll = () => {
    if (searchValue) return; 
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (sessionPageCount > pageComments + 1) {
        setPageComments((prevPage) => {
          const nextPage = prevPage + 1;
          onApiCallGetSessionList(dropdownSelected, nextPage);
          return nextPage;
        });
      }
    }
  };

  const onApiCallGetSessionOverviewDetails = () => {
    try {
      dispatch(setLoadingTrue());
      postSessionGetOverview().then((response) => {
        setSessionOverview(response.response);
        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const onApiCallGetSessionOverView = (type) => {
    if (type == "call") {
      let param = {
        sessionType: "call",
      };
      try {
        dispatch(setLoadingTrue());
        get_session_overview_details(param).then((response) => {
          setCallSessionData(response.data);
          dispatch(setLoadingFalse());
        });
      } catch (err) {
        dispatch(setLoadingFalse());
      }
    } else if (type == "chat") {
      let param = {
        sessionType: "chat",
      };
      try {
        dispatch(setLoadingTrue());
        get_session_overview_details(param).then((response) => {
          setChatSessionData(response.data);

          dispatch(setLoadingFalse());
        });
      } catch (err) {
        dispatch(setLoadingFalse());
      }
    } else {
      let param = {
        sessionType: "live",
      };
      try {
        dispatch(setLoadingTrue());
        get_session_overview_details(param).then((response) => {
          setLiveSessionData(response.data);
          dispatch(setLoadingFalse());
        });
      } catch (err) {
        dispatch(setLoadingFalse());
      }
    }
  };

  const handleSelect = (sessionStatus) => {
    setDropDownSelectedValue(sessionStatus);
    setSearchValue("");
    if (sessionStatus === "All") {
      sessionListData(sessionAllList); // Show all items
    } else {
      const statusMap = {
        Approved: "approved",
        Completed: "completed",
        Canceled: "cancel",
        Declined: "declined",
        Missed: "missed",
        Accept: "accept",
      };

      const filteredData = sessionAllList.filter(
        (astrologer) => astrologer.sessionStatus === statusMap[sessionStatus]
      );
      sessionListData(filteredData);
    }
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = sessionAllList.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      sessionListData(newData);
    } else {
      sessionListData(sessionAllList);
    }
  };
  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  const [sortOrder, setSortOrder] = useState("asc");
  const parseAvgTime = (value) => {
    const numericValue = parseFloat(
      value.replace(/₹/g, "").replace(/\/min/g, "")
    );
    return numericValue;
  };
  const onCLickSortingArray = (property) => {
    const sortedData = [...sessionList];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return parseAvgTime(a[property]) - parseAvgTime(b[property]);
      } else {
        return parseAvgTime(b[property]) - parseAvgTime(a[property]);
      }
    });
    sessionListData(sortedData);
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Sessions"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total sessions</div>
                <div className="fontSize24">
                  {sessionOverview?.totalSession}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Completed sessions</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {sessionOverview?.completedSession}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Schedule sessions</div>
                <div
                  style={{ color: Colors.darkOrange }}
                  className="fontSize24"
                >
                  {sessionOverview?.scheduleSession}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Canceled sessions</div>
                <div style={{ color: Colors.darkRed }} className="fontSize24">
                  {sessionOverview?.canceledSession}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionDivStyle">
          <div className="session_main_div_container">
            <div className="session_main_div_parent">
              <div className="session_main_div_third_parent">
                <div className="contentTitle24Weight40">Call session</div>
                <div className="session_custom_dropdown_div_container">
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={items}
                    onSelect={DropdownCallsessionHandle}
                  />
                </div>
              </div>
              <div className="session_details_main_Div_View">
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Total session</div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.white,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {callSessionData?.totalSessions}
                  </div>
                </div>
                <div className="session_border_top" />
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Completed</div>
                  <div
                    style={{
                      color: Colors.darkGreen,
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {callSessionData?.completed}
                  </div>
                </div>
                <div className="session_border_top" />
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Scheduled</div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.darkBlue,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {callSessionData?.scheduled}
                  </div>
                </div>
              </div>
              <div className="session_border_top" />
              <div className="session_title_div_continer">
                <div className="text_grey_title">Canceled</div>
                <div
                  style={{
                    position: "relative",
                    color: Colors.darkRed,
                    fontWeight: "500",
                    fontSize: "1.3rem",
                  }}
                  className="title24"
                >
                  {callSessionData?.canceled}
                </div>
              </div>
            </div>
          </div>
          <div className="session_main_div_container">
            <div className="session_main_div_parent">
              <div className="session_main_div_third_parent">
                <div className="contentTitle24Weight40">Chat session</div>
                <div className="session_custom_dropdown_div_container">
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={items}
                    onSelect={DropdownCallsessionHandle}
                  />
                </div>
              </div>
              <div className="session_details_main_Div_View">
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Total session</div>
                  <div
                    style={{
                      position: "relative",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {chatSessionData?.totalSessions}
                  </div>
                </div>
                <div className="session_border_top" />
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Completed</div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.darkGreen,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {chatSessionData?.completed}
                  </div>
                </div>
                <div className="session_border_top" />
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Scheduled</div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.darkBlue,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {chatSessionData?.scheduled}
                  </div>
                </div>
                <div className="session_border_top" />
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Canceled</div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.darkRed,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {chatSessionData?.canceled}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="session_main_div_container">
            <div className="session_main_div_parent">
              <div className="session_main_div_third_parent">
                <div className="contentTitle24Weight40">Live session</div>
                <div
                  onClick={() => navigate("/SessionLive")}
                  className="text_grey_title"
                  style={{
                    cursor: "pointer",
                    justifyContent: "flex-end",
                    fontWeight: 400,
                    display: "flex",
                  }}
                >
                  View all
                </div>
              </div>
              <div className="session_details_main_Div_View">
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Total session</div>
                  <div
                    style={{
                      position: "relative",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {liveSessionData?.totalSessions}
                  </div>
                </div>
                <div className="session_border_top" />
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Completed</div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.darkGreen,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {liveSessionData?.completed}
                  </div>
                </div>
                <div className="session_border_top" />
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Scheduled</div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.darkBlue,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {liveSessionData?.scheduled}
                  </div>
                </div>
                <div className="session_border_top" />
                <div className="session_title_div_continer">
                  <div className="text_grey_title">Canceled</div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.darkRed,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                    }}
                    className="title24"
                  >
                    {liveSessionData?.canceled}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            alignSelf: "stretch",
          }}
        >
          <div style={{}} className="sectionDivStyle">
            <div
              className="title24FontWeight400WHite"
              style={{
                flex: "1",
                position: "relative",
                alignSelf: "stretch",
                alignItems: "center",
                display: "flex",
              }}
            >
              Session list
            </div>
            <div className="frame-parent2">
              <div className="custom-box-user">
                <div className="custom-container-dropdown-div">
                  <CustomDropdown
                    dropDownLabel={dropdownSelected}
                    items={statusDropdownItem}
                    onSelect={(item) => setDropDownSelectedValue(item)}
                    customLabelStyle={{
                      fontWeight: "400",
                      color: Colors.greyColor50,
                    }}
                  />
                </div>

                <div className="custom-box-search-div">
                  <img className="custom-image" alt="" src={Icons.ic_search} />
                  <input
                    type="text"
                    className="searchTextInput"
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <SessionList
            handleScroll={handleScroll}
            commentsContainerRef={commentsContainerRef}
            onSortingList={() => onCLickSortingArray("totalFees")}
            data={sessionList}
            sessionId={"Session ID"}
            user={"User"}
            astrologer={"Astrologer"}
            dateTime={"Date & Time"}
            sessionType={"Session Type"}
            duration={"Duration"}
            fees={"Fees"}
            totalfees={"Total fees"}
            status={"Status"}
          />
        </div>
        {sessionList.length <= 0 && <div style={{
            alignItems: "center",
        display: "flex",
        flex: 1,
        // border:"1px solid red",
        alignSelf: "stretch",
        color: "#FFF",
        marginBottom:"70px",
        fontWeight: 800,
        justifyContent: "center",}}> <EmptyData /></div>}
      </div>
    </div>
  );
}

export default Session;

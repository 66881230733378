import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import Header from "../../../Components/Header/Header";
import Icons from "../../../Constants/Icons";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomUploadProjectImage } from "../../../Core/CustomUploadProjectImage";
import {
  onApiCallShopGetAllShopCategories,
  post_add_product,
  post_api_edit_product_inventory,
} from "../../../Core/Apicall";
import moment from "moment";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";

function AddInventory() {
  const isProfile = useSelector((state) => state.isProfileData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fileProductInputRef = useRef(null);
  const fileProductInputSMultiRef = useRef(null);
  const location = useLocation();
  const { data, dataEdit, dataDuplicate } = location.state || {};
  const [categoriesShop, setCategoriesShop] = useState([]);
  const [initalCategoriesShop, setInitialCategoriesShop] = useState([]);

  const [formData, setFormData] = useState({
    productName: data?.productName ?? "",
    skuId: data?.skuId ?? "",
    mainProductImage: data?.mainProductImage ?? "",
    images: data?.images ?? [],
    description: data?.description ?? "",
    about: data?.about ?? "",
    weight: data?.weight ?? "",
    price: data?.price ?? "",
    discount_price: data?.discount_price ?? "",
    cost_per_item: data?.cost_per_item ?? "",
    profit: data?.profit ?? "",
    margin: data?.margin ?? "",
    initial_quantity: data?.initial_quantity ?? "",
    reorder_print: data?.reorder_print ?? "",
    weight_kg: data?.weight_kg ?? "Kg",
    product_status: data?.product_status ?? "",
    shop_category: "",
    physicalProductChecked: data?.physicalProductChecked ?? false,
    chargeTaxChargeProduct: data?.chargeTaxChargeProduct ?? false,
  });

  const [isValidityShow, setIsValidityShow] = useState(false);
  const [isValidityToDate, setIsValidityDate] = useState(
    data?.isValidityToDate ?? new Date()
  );

  const [formErrors, setFormErrors] = useState({
    productName: false,
    skuId: false,
    images: false,
    description: false,
    about: false,
    weight: false,
    price: false,
    discount_price: false,
    cost_per_item: false,
    mainProductImage: false,
    profit: false,
    margin: false,
    initial_quantity: false,
    reorder_print: false,
    weight_kg: false,
    product_status: false,
    shop_category: false,
    physicalProductChecked: false,
    chargeTaxChargeProduct: false,
  });

  useEffect(() => {
    onApiCallShopCategory();
  }, []);

  const onApiCallShopCategory = async () => {
    dispatch(setLoadingTrue());
    try {
      const res = await onApiCallShopGetAllShopCategories();
      setInitialCategoriesShop(res?.Categories);
      const categoryNames = res?.Categories.map((item) => item?.categoryName);
      const shopTypeSelect =
        res?.Categories?.find((item) => item?._id === data?.shop_category)
          ?.categoryName ?? "";
      setFormData((prevFormData) => ({
        ...prevFormData,
        shop_category: shopTypeSelect,
      }));
      setCategoriesShop(categoryNames);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const handleImageChangeProduct = async (event) => {
    const formData = new FormData();
    formData.append("productImages", event.target.files[0]);
    formData.append("productSKUID", 123456);
    try {
      const data = await CustomUploadProjectImage(formData, isProfile.token);
      if (data && data.status === true) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          mainProductImage: data.location,
        }));
      } else {
      }
    } catch (error) {}
  };

  const handleImageChange = async (event) => {
    const formData = new FormData();
    formData.append("productImages", event.target.files[0]);
    formData.append("productSKUID", 123456);
    try {
      const data = await CustomUploadProjectImage(formData, isProfile.token);
      if (data && data.status === true) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          images: [...prevFormData.images, data.location],
        }));
      } else {
      }
    } catch (error) {}
  };

  const handleRemoveImage = (index) => {
    const newImages = [...formData.images];
    newImages.splice(index, 1);
    setFormData({
      ...formData,
      ["images"]: newImages,
    });
  };

  const handleInputChange = (e, fieldName) => {
    let value = e.target.value;
    if (
      fieldName == "price" ||
      fieldName == "discount_price" ||
      fieldName == "cost_per_item" ||
      fieldName == "profit" ||
      fieldName == "margin" ||
      fieldName == "initial_quantity" ||
      fieldName == "reorder_print"
    ) {
      value = value.replace(/[^0-9]/g, "");
    }

    if (fieldName == "weight") {
      value = value.replace(/[^0-9.]/g, "");
    }

    if (fieldName == "discount_price") {
      if (parseInt(value, 10) > parseInt(formData.price, 10)) {
        alert("Discount amount cannot be greater than price amount");
        return;
      }
      setFormData({
        ...formData,
        [fieldName]: value,
        cost_per_item: "",
        profit: "",
        margin: "",
      });
    } else if (fieldName == "cost_per_item") {
      if (parseInt(value, 10) > parseInt(formData.discount_price, 10)) {
        alert("Cost per item  cannot be greater than discount amount");
        return;
      }
      let profitSet =
        parseInt(formData.discount_price || "0", 10) -
        parseInt(value || "0", 10);
      let marginSet = (parseInt(profitSet) * 100) / formData.discount_price;
      setFormData({
        ...formData,
        [fieldName]: value,
        profit: profitSet.toFixed(2),
        margin: marginSet.toFixed(2),
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }

    // let data = (value * 100) / formData.priceperminut;
    // let marginPer = (formData.zigzekmargin * 100) / value;

    // let zigzekMarginn =
    //   parseInt(value || "0", 10) > parseInt(formData.zigzekmargin || "0", 10)
    //     ? parseInt(value || "0", 10) -
    //       parseInt(formData.zigzekmargin || "0", 10)
    //     : parseInt(formData.zigzekmargin || "0", 10) -
    //       parseInt(value || "0", 10);
    // if (!isFinite(marginPer)) {
    //   marginPer = 0;
    // }
    // if (!isFinite(zigzekMarginn)) {
    //   zigzekMarginn = 0;
    // }

    // let finalDis = 100 - data.toFixed(2);
  };

  const validateForm = () => {
    const errors = {
      productName: formData?.productName == "",
      skuId: formData?.skuId == "",
      images: formData?.images.length == [],
      description: formData?.description == "",
      about: formData?.about == "",
      weight: formData?.weight == "",
      price: formData?.price == "",
      discount_price: formData?.discount_price == "",
      cost_per_item: formData?.cost_per_item == "",
      profit: formData?.profit == "",
      margin: formData?.margin == "",
      initial_quantity: formData?.initial_quantity == "",
      reorder_print: formData?.reorder_print == "",
      product_status: formData?.product_status == "",
      shop_category: formData?.shop_category == "",
      mainProductImage: formData?.mainProductImage == "",
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };
  const onClickSave = async () => {
    if (validateForm()) {
      let param = {
        categoryId: initalCategoriesShop.filter(
          (item) => item?.categoryName == formData?.shop_category
        )[0]._id,
        productName: formData.productName,
        productPrice: formData.price,
        productImage: formData.mainProductImage,
        productStatus:
          formData.product_status == "In Stock"
            ? "inStock"
            : formData.product_status == "Out Stock"
            ? "outOfStock"
            : formData.product_status == "Low Stock"
            ? "lowStock"
            : "inActive",
        productDescription: formData.description,
        aboutProduct: formData.about,
        productImages: formData.images,
        isPhysicalProduct: formData.physicalProductChecked,
        SKUId: formData.skuId,
        weight: formData.weight,
        beforeDiscountAmount: formData.discount_price,
        costPerItemAmount: formData.cost_per_item,
        profitAmount: formData.profit,
        profitPercentage: "12",
        initialQuantityOnHand: formData.initial_quantity,
        date: moment(isValidityToDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
        reOrderPoint: formData.reorder_print,
      };
      dispatch(setLoadingTrue());
      try {
        if (dataEdit) {
          const editParam = {
            ...param,
            productId: data?.productId,
          };
          await post_api_edit_product_inventory(editParam);
        } else {
          await post_add_product(param);
        }
        dispatch(setLoadingFalse());
        navigate(-1);
      } catch (error) {
        dispatch(setLoadingFalse());
      }
    }
  };
  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
        paddingBottom: "150px",
      }}
    >
      <Header
        name={`Zigzek shop / Inventory / ${
          dataEdit ? "Edit" : "Add new"
        } product`}
      />
      <div className="add-inventory-container">
        <div style={{}}>
          <div
            style={{
              display: "flex",

              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              fontSize: "1rem",
            }}
          >
            <div
              style={{ background: "#25252D" }}
              className="add-inventory-main-div-section"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="fontSize24" style={{ fontWeight: 700 }}>
                  Product Details
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  <div
                    className="fontSize16"
                    onClick={() => navigate(-1)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 500,
                      border: "1px solid #ffffff10",
                      borderRadius: "50px",
                      padding: "7px 15px 7px 15px",
                      cursor: "pointer",
                    }}
                  >
                    Discard
                  </div>
                  <div
                    className="fontSize16"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 500,
                      border: "1px solid #ffffff10",
                      background: "#FFF",
                      color: "#1E1E25",
                      borderRadius: "50px",
                      padding: "8px 15px 8px 15px",
                    }}
                    onClick={() => onClickSave()}
                  >
                    Save
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "20px" }}>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Product category
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <div
                      style={{
                        padding: "5px 10px 5px 10px",
                        border: formErrors.shop_category
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <CustomDropdown
                        customLabelStyle={{
                          color: formData?.shop_category
                            ? "#FFF"
                            : "#FFFFFF33",
                        }}
                        dropDownLabel={
                          formData?.shop_category == ""
                            ? "Select Category"
                            : formData?.shop_category
                        }
                        items={categoriesShop}
                        onSelect={(value) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            shop_category: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-second-div-title">
                <div style={{}} className="fontSize16">
                  Product name
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    border: formErrors.productName
                      ? "1px solid red"
                      : "1px solid rgba(255, 255, 255, 0.2)",
                  }}
                  className="modal-coupon-code-type"
                >
                  <input
                    maxLength={30}
                    className="modal-dropdown"
                    type="text"
                    placeholder="eg. Rudraksha"
                    value={formData.productName}
                    onChange={(e) => handleInputChange(e, "productName")}
                  />
                </div>
              </div>
              <div style={{}} className="modal-second-div-title">
                <div style={{}} className="fontSize16">
                  SKU - Id
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    border: formErrors.skuId
                      ? "1px solid red"
                      : "1px solid rgba(255, 255, 255, 0.2)",
                  }}
                  className="modal-coupon-code-type"
                >
                  <input
                    maxLength={30}
                    className="modal-dropdown"
                    type="text"
                    placeholder="eg. RDR-NP-5MK"
                    value={formData.skuId}
                    onChange={(e) => handleInputChange(e, "skuId")}
                  />
                </div>
              </div>

              <div className="modal-second-div-title">
                <div style={{}} className="fontSize16">
                  Product image
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    alignSelf: "stretch",
                    borderRadius: "16px",
                    border: formErrors.mainProductImage
                      ? "1px solid red"
                      : "1px solid rgba(255, 255, 255, 0.2)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "2rem 1rem",
                    gap: "0.5rem",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <input
                    type="file"
                    name="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileProductInputRef}
                    onChange={handleImageChangeProduct}
                  />
                  <img
                    style={{
                      position: "relative",
                      width: "3.25rem",
                      height: "3.25rem",
                      cursor: "pointer",
                      objectFit: "contain",
                    }}
                    alt=""
                    src={
                      formData.mainProductImage
                        ? formData.mainProductImage
                        : Icons.ic_upload_photo
                    }
                    onClick={() => {
                      fileProductInputRef.current.click();
                    }}
                  />
                  <div style={{ color: "#FFFFFF80" }} className="fontSize16">
                    Upload image
                  </div>
                </div>
              </div>

              <div className="modal-second-div-title">
                <div style={{}} className="fontSize16">
                  Product images
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    alignSelf: "stretch",
                    borderRadius: "16px",
                    border: formErrors.images
                      ? "1px solid red"
                      : "1px solid rgba(255, 255, 255, 0.2)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "3.25rem 1rem",
                    gap: "0.5rem",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <input
                    type="file"
                    name="file"
                    accept="image/*"
                    ref={fileProductInputSMultiRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <img
                    style={{
                      position: "relative",
                      width: "3.25rem",
                      height: "3.25rem",
                      cursor: "pointer",

                      objectFit: "cover",
                    }}
                    alt=""
                    src={Icons.ic_upload_photo}
                    onClick={() => {
                      fileProductInputSMultiRef.current.click();
                    }}
                  />
                  <div style={{ color: "#FFFFFF80" }} className="fontSize16">
                    Upload image
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "1rem",
                    justifyContent: "center",
                  }}
                >
                  {formData.images.map((imageData, index) => (
                    <div key={index} style={{ position: "relative" }}>
                      <img
                        src={imageData}
                        alt={`Uploaded ${index}`}
                        style={{
                          width: "5rem",
                          borderRadius: "10px",
                          height: "5rem",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      />

                      <button
                        onClick={() => handleRemoveImage(index)}
                        style={{
                          position: "absolute",
                          top: "-7px",
                          right: "10px",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={Icons.ic_close}
                          style={{
                            position: "absolute",
                            width: "1rem",
                            height: "1rem",
                          }}
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="modal-second-div-title">
                <div style={{}} className="fontSize16">
                  Description
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    borderRadius: "10px",
                    padding: "10px",
                    border: formErrors.description
                      ? "1px solid red"
                      : "1px solid rgba(255, 255, 255, 0.2)",
                  }}
                  className="modal-coupon-code-type"
                >
                  <textarea
                    maxLength={150}
                    className="modal-dropdown"
                    type="text"
                    style={{ height: "5rem", flex: 1, paddingLeft: "0rem" }}
                    placeholder="Description product"
                    value={formData.description}
                    onChange={(e) => handleInputChange(e, "description")}
                  />
                </div>
              </div>

              <div className="modal-second-div-title">
                <div style={{}} className="fontSize16">
                  About
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    borderRadius: "10px",
                    padding: "10px",
                    border: formErrors.about
                      ? "1px solid red"
                      : "1px solid rgba(255, 255, 255, 0.2)",
                  }}
                  className="modal-coupon-code-type"
                >
                  <textarea
                    maxLength={150}
                    className="modal-dropdown"
                    type="text"
                    style={{ height: "5rem", flex: 1, paddingLeft: "0rem" }}
                    placeholder="About product"
                    value={formData.about}
                    onChange={(e) => handleInputChange(e, "about")}
                  />
                </div>
              </div>

              <div className="modal-main-div">
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Switch
                    checked={formData.physicalProductChecked}
                    onChange={(value) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        physicalProductChecked: value,
                      }));
                    }}
                    onColor={Colors.darkGreen}
                    offColor="#818181"
                    onHandleColor="#818181"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    className="react-switch"
                    id="material-switch"
                  />
                  <div className="fontSize16">This is a physical product</div>
                </div>
              </div>

              <div
                style={{ marginTop: "5px" }}
                className="modal-second-div-title"
              >
                <div className="fontSize16">Weight(Kgs)</div>
                <div
                  style={{ marginTop: "5px", display: "flex", gap: "0.7rem" }}
                >
                  <input
                    style={{
                      flex: 0.1,
                      border: formErrors.weight
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-second-dropdown"
                    type="text"
                    placeholder="0.0"
                    value={formData.weight}
                    onChange={(e) => handleInputChange(e, "weight")}
                  />
                  <div>
                    {/* <div style={{ marginTop: "5px" }}>
                      <div
                        style={{ width: "5rem" }}
                        className="modal-from-to-div"
                      >
                        <CustomDropdown
                          customLabelStyle={{ fontSize: "0.7rem" }}
                          dropDownLabel={"Kg"}
                          items={["Kg", "Kgs"]}
                          onSelect={(value) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              weight_kg: value,
                            }));
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ background: "#25252D" }}
              className="add-inventory-main-div-section"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="fontSize24" style={{ fontWeight: 700 }}>
                  Pricing
                </div>
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Switch
                    checked={formData.chargeTaxChargeProduct}
                    onChange={(value) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        chargeTaxChargeProduct: value,
                      }));
                    }}
                    onColor={Colors.darkGreen}
                    offColor="#818181"
                    onHandleColor="#818181"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    className="react-switch"
                    id="material-switch"
                  />
                  <div className="fontSize16">Charge tax on this product</div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "20px" }}>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Price
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.price
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="₹"
                      value={"₹" + formData.price}
                      onChange={(e) => handleInputChange(e, "price")}
                    />
                  </div>
                </div>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Discount price
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      background: formData.price == "" && "#FFFFFF1A",
                      border: formErrors.discount_price
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      disabled={formData.price == "" && true}
                      type="text"
                      placeholder="₹"
                      value={"₹" + formData.discount_price}
                      onChange={(e) => handleInputChange(e, "discount_price")}
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", marginTop: "10px", gap: "20px" }}>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Cost per item
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      background: formData.discount_price == "" && "#FFFFFF1A",
                      border: formErrors.cost_per_item
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      disabled={formData.discount_price == "" && true}
                      placeholder="₹"
                      value={"₹" + formData.cost_per_item}
                      onChange={(e) => handleInputChange(e, "cost_per_item")}
                    />
                  </div>
                </div>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Profit
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      background: "#FFFFFF1A",
                      border: formErrors.profit
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      disabled
                      className="modal-dropdown"
                      type="text"
                      placeholder="₹"
                      value={"₹" + formData.profit}
                      onChange={(e) => handleInputChange(e, "profit")}
                    />
                  </div>
                </div>

                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Margin
                  </div>
                  <div
                    style={{
                      background: "#FFFFFF1A",
                      marginTop: "5px",
                      border: formErrors.margin
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      disabled
                      className="modal-dropdown"
                      type="text"
                      placeholder=""
                      value={formData.margin}
                      onChange={(e) => handleInputChange(e, "margin")}
                    />
                    <div style={{ color: "rgba(255, 255, 255, 0.2)" }}>
                      {"%"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ background: "#25252D" }}
              className="add-inventory-main-div-section"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="fontSize24" style={{ fontWeight: 700 }}>
                  Stock
                </div>
              </div>

              <div style={{ display: "flex", marginTop: "10px", gap: "20px" }}>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Initial quantity on hand
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.initial_quantity
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="Enter quantity"
                      value={formData.initial_quantity}
                      onChange={(e) => handleInputChange(e, "initial_quantity")}
                    />
                  </div>
                </div>

                <div className="modal-second-div-title">
                  <div className="fontSize16">As of date</div>
                  <div className="">
                    <div
                      style={{ flex: 1 }}
                      className="date-outside-div"
                      onClick={() => setIsValidityShow(true)}
                    >
                      <DatePicker
                        showPopperArrow={false}
                        onClickOutside={() => setIsValidityShow(false)}
                        onChange={(date) => setIsValidityDate(date)}
                        minDate={new Date()}
                        dateFormat="dd-MM-yyyy"
                        customInput={
                          <div className="date-picker-date-show">
                            <img
                              src={Icons.ic_grey_calendar}
                              className="modal-from-to-icon"
                            />
                            <div className="fontSize16">
                              {isValidityToDate.toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </div>
                          </div>
                        }
                        selected={isValidityToDate}
                        open={isValidityShow}
                        style={{
                          border: "2px solid #3498db",
                          borderRadius: "5px",
                          padding: "5px",
                          backgroundColor: "red",
                          color: "black",
                        }}
                        onBlur={() => setIsValidityShow(false)}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Reorder point
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.reorder_print
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="Enter quantity"
                      value={formData.reorder_print}
                      onChange={(e) => handleInputChange(e, "reorder_print")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ background: "#25252D" }}
              className="add-inventory-main-div-section"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="fontSize24" style={{ fontWeight: 700 }}>
                  Status
                </div>
              </div>

              <div style={{ display: "flex", gap: "20px" }}>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Product Status
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <div
                      style={{
                        padding: "5px 10px 5px 10px",
                        border: formErrors.product_status
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <CustomDropdown
                        customLabelStyle={{
                          color: formData?.product_status
                            ? "#FFF"
                            : "#FFFFFF33",
                        }}
                        dropDownLabel={
                          formData?.product_status == ""
                            ? "Select status"
                            : formData?.product_status
                        }
                        items={[
                          "In Stock",
                          "Out Stock",
                          "Low Stock",
                          "In Active",
                        ]}
                        onSelect={(value) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            product_status: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddInventory;

import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../../Constants/Colors";
import { Doughnut } from "react-chartjs-2";
import CustomDropdown from "../../../../Components/DropDown/CustomDropdown";
import Icons from "../../../../Constants/Icons";
import Earningslist from "../Usertransaction/Earningslist";
import Header from "../../../../Components/Header/Header";
import {
  apiCallEarningTransactionHistory,
  apiCallTransactionEarningOverview,
} from "../../../../Core/Apicall";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import moment from "moment";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

function Earnings() {
  const tabs = ["Shop", "Session"];
  const items = ["Today", "This Week", "Last Week", "This Month", "All Time"];
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [transactionAllHistoryData, setAllTransactionHistoryData] = useState(
    []
  );
  const [activeTab, setActiveTab] = useState(0);
  const statusDropdownTypeTran = [
    "All",
    "Earnings on session",
    "Earnings on zigzek shop",
  ];
  const statusDropdownItem = ["All", "In progress", "Failed", "Successful"];
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [transactionDropdownLabel, setTransactionTypeDropDownSelectedValue] =
  useState("Transaction Type");

  const [dropdownSelected, setDropDownSelectedValue] = useState("All");
  const [earningOverView, setEarningOverview] = useState();
  const [earningOverViewChart, setEarningOverviewChart] = useState();
  const [doughtChart, setDoughtChart] = useState();

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    onApiCallEarningOverviewTax("Today");
    onApiCallEarningOverviewChart("Today");
    onAPiCallEarningHistory();
  }, [activeTab, pageComments]);

  const onAPiCallEarningHistory = (selectedPage) => {
    try {
      dispatch(setLoadingTrue());
      let param = {
        offset: 30 * pageComments,
        limit: 30,
      };
      let data = {
        type: activeTab == 0 ? "shop" : "session",
      };
      apiCallEarningTransactionHistory(param, data).then((response) => {
        setTotalPageCount(
          Math.ceil(
            activeTab == 0
              ? response.totalOrdersCount / 30
              : response.totalSessionsCount / 30
          )
        );
        setSearchValue("");
        setDropDownSelectedValue("All");
        // setTransactionHistoryData(response?.data);
        // setAllTransactionHistoryData(response?.data);

        setTransactionHistoryData((prevData) => [
          ...prevData,
          ...response?.data,
        ]);
        setAllTransactionHistoryData((prevData) => [
          ...prevData,
          ...response?.data,
        ]);

        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const onApiCallEarningOverviewTax = (type) => {
    try {
      const param = {
        filterType:
          type === "Today"
            ? "today"
            : type === "This Week"
            ? "thisWeek"
            : type === "Last Week"
            ? "lastWeek"
            : type === "This Month"
            ? "thisMonth"
            : "allTime",
      };
      dispatch(setLoadingTrue());
      apiCallTransactionEarningOverview(param).then((response) => {
        setEarningOverview(response);
        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };
  const onApiCallEarningOverviewChart = (type) => {
    try {
      dispatch(setLoadingTrue());
      const param = {
        filterType:
          type === "Today"
            ? "today"
            : type === "This Week"
            ? "thisWeek"
            : type === "Last Week"
            ? "lastWeek"
            : type === "This Month"
            ? "thisMonth"
            : "allTime",
      };
      apiCallTransactionEarningOverview(param).then((response) => {
        setEarningOverviewChart(response)
        const chartData = {
          labels: ["Earnings on session", "Earnings on zigzek shop"],
          datasets: [
            {
              label: "",
              data: [
                response?.earningData?.earningOnSessionAmount,
                response?.earningData?.earningOnShopAmount,
              ],
              backgroundColor: [Colors.darkBlue, Colors.darkGreen],
              borderColor: Colors.black,
              borderWidth: 1,
            },
          ],
        };
        setDoughtChart(chartData);
        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const handleSelect = (dateTime, transactionType) => {
    setSearchValue("");

    const currentDate = new Date();
    let startDate;

    switch (dateTime) {
      case "All time":
        filterDataByDateRange(null, null, transactionType); // Show all items
        break;
      case "Last 7 days":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 7);
        filterDataByDateRange(startDate, currentDate, transactionType);
        break;
      case "Last 30 days":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 30);
        filterDataByDateRange(startDate, currentDate, transactionType);
        break;
      case "Custom":
        break;
      default:
        break;
    }
  };

  const filterDataByDateRange = (startDate, endDate, transactionType) => {
    const filteredData = transactionAllHistoryData.filter((entry) => {
      const entryDate = new Date(entry.dateTime);
      return (
        (!startDate || entryDate >= startDate) &&
        (!endDate || entryDate <= endDate) &&
        (transactionType === "All" || entry.transactionType === transactionType)
      );
    });
    setTransactionHistoryData(filteredData);
  };
  const byStatusSort = (sessionStatus) => {
    setDropDownSelectedValue(sessionStatus);
    setSearchValue("");
    if (sessionStatus === "All") {
      setTransactionHistoryData(transactionAllHistoryData);
    } else {
      const statusMap = {
        Successful: "successful",
        Failed: "failed",
        "In Progress": "inProgress",
      };
      const filteredData = transactionAllHistoryData.filter(
        (item) => item.status === statusMap[sessionStatus]
      );
      setTransactionHistoryData(filteredData);
    }
  };

  const sortByDuration = (property) => {
    if (property === "Date & Time") {
      const sortedData = [...transactionHistoryData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      sortedData.sort((a, b) => {
        const dateA = moment(a.date_and_time);
        const dateB = moment(b.date_and_time);
        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      setTransactionHistoryData(sortedData);
    }
  };

  const sortByAMount = (property) => {
    const sortedData = [...transactionHistoryData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[property] - b[property];
      } else {
        return b[property] - a[property];
      }
    });
    setTransactionHistoryData(sortedData);
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = transactionAllHistoryData.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setTransactionHistoryData(newData);
    } else {
      setTransactionHistoryData(transactionAllHistoryData);
    }
  };
  const handleInputChange = (e) => {
    setDropDownSelectedValue("All");
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalPageCount > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Transaction/Earnings"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="sectionDivStyle">
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              borderRadius: "16px",
              backgroundColor: "#25252d",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "1rem 0rem 0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0rem 1rem",
                  gap: "1rem",
                }}
              >
                <div className="contentTitle24Weight40">Overview</div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.38rem 0.75rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={items}
                    onSelect={(item)=>onApiCallEarningOverviewTax(item)}
                  />
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  fontSize: "1rem",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total amount
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                    className="title24"
                  >
                    ₹ {earningOverView?.overviewData?.totalAmount}
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "0.06rem",
                  }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Zigzek margin
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "white",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                    className="title24"
                  >
                    ₹ {earningOverView?.overviewData?.zigzakMargin}
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "0.06rem",
                  }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Avg. amount user spend
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "white",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                    className="title24"
                  >
                    ₹ {earningOverView?.overviewData?.avgAmountUserSpend}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              // width: "49.38rem",
              alignSelf: "stretch",
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="contentTitle24Weight40"
                  style={{ flex: "1", position: "relative" }}
                >
                  Earnings
                </div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.38rem 0.75rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={items}
                    onSelect={(item)=>onApiCallEarningOverviewChart(item)}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",

                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "12rem",
                  alignItems: "center",
                  display: "flex",
                  zIndex: 1,
                  height: "12rem",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "90%",
                    width: "90%",
                    zIndex: -1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="lastWeekEndDiv"
                    style={{ fontWeight: "600", color: Colors.secondary }}
                  >
                    Total
                  </div>
                  <div
                    className="astrologerText"
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    ₹ {earningOverViewChart?.earningData?.totalEarning}
                  </div>
                </div>
                <div
                  style={{
                    height: "90%",
                    width: "90%",
                  }}
                >
                  {doughtChart && (
                    <Doughnut
                      options={{
                        cutout: "75%",
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                      data={doughtChart}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  rowGap: "20px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignSelf: "stretch",
                    alignItems: "baseline",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkGreen,
                      width: "0.7rem",
                      height: "0.7rem",
                      left: "17px",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    <span style={{ marginLeft: "15px" }}>
                      Earnings on session
                    </span>
                    <br />
                    <div
                      style={{ fontWeight: "400" }}
                      className="lastWeekEndDiv"
                    >
                      ₹ {earningOverViewChart?.earningData?.earningOnSessionAmount}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    alignSelf: "stretch",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkBlue,
                      width: "0.7rem",
                      height: "0.7rem",
                      left: "17px",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      marginLeft: "8px",
                      color: Colors.secondary,
                      fontWeight: "400",
                    }}
                    className="lastWeekEndDiv"
                  >
                    <span style={{ marginLeft: "15px" }}>
                      Earnings on zigzek shop
                    </span>
                    <br />
                    <div
                      style={{ fontWeight: "400" }}
                      className="lastWeekEndDiv"
                    >
                      ₹ {earningOverViewChart?.earningData?.earningOnShopAmount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionDivStyle">
          <div
            className="title24FontWeight400WHite"
            style={{
              flex: "1",
              position: "relative",
              alignSelf: "stretch",
              alignItems: "center",
              display: "flex",
            }}
          >
            Transaction history
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              fontSize: "1rem",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
           <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "12rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={transactionDropdownLabel}
                items={statusDropdownTypeTran}
                color="blue"
                onSelect={(item) => {
                  setTransactionTypeDropDownSelectedValue(item);
                  alert("Api Pending");
                }}
                customLabelStyle={{
                  fontWeight: "400",
                  color:
                    // transactionDropdownLabel == "Transaction Type"
                    //   ?
                    Colors.greyColor50,
                  // : Colors.white,
                }}
              />
            </div>

            <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "9.38rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={dropdownSelected}
                items={statusDropdownItem}
                color="blue"
                onSelect={byStatusSort}
                customLabelStyle={{
                  fontWeight: "400",
                  color: Colors.greyColor50,
                }}
              />
            </div>

            <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "9.38rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={"All time"}
                items={items}
                onSelect={handleSelect}
              />
            </div>

            <div
              style={{
                borderRadius: "8px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                boxSizing: "border-box",
                width: "15.75rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0.5rem 0.5rem",
                gap: "0.5rem",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "1.5rem",
                  height: "1.5rem",
                  objectFit: "contain",
                }}
                alt=""
                src={Icons.ic_search}
              />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            gap: "1rem",
            marginTop: "-20px",
            alignSelf: "stretch",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          {tabs.map((tab, index) => (
            <div
              key={index}
              style={{
                padding: "10px",
                fontWeight: "400",
                color:
                  index != activeTab ? "rgba(255, 255, 255, 0.5)" : " #fff",
                borderBottom: index != activeTab ? "" : "1px solid #fff",
                cursor: "pointer",
              }}
              className="fontSize18"
              onClick={() => {
                setTransactionHistoryData([]);
                setAllTransactionHistoryData([]);
                setPageComments(0);
                setActiveTab(index);
              }}
            >
              {tab}
            </div>
          ))}
        </div>

        <Earningslist
          handleScroll={handleScroll}
          commentsContainerRef={commentsContainerRef}
          activeTab={activeTab}
          data={transactionHistoryData}
          transactionID={"Transaction ID"}
          from={"From"}
          To={"To"}
          dateTimeSort={() => sortByDuration("Date & Time")}
          Amount={"Amount"}
          Zigzekmargin={"Zigzek margin"}
          dateTime={"Date & Time"}
          status={"Status"}
          amountSort={(item) => sortByAMount(item)}
        />
      </div>
      {transactionHistoryData.length <= 0 && <EmptyData />}
      {/* {transactionHistoryData.length != 0 && (
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            pageCount={totalPageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onAPiCallEarningHistory}
          />
        </div>
      )} */}
    </div>
  );
}
export default Earnings;

import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../../Constants/Colors";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import CustomDropdown from "../../../../Components/DropDown/CustomDropdown";
import Icons from "../../../../Constants/Icons";
import Usertransactions from "../Usertransaction/Usertransactionlist";
import Header from "../../../../Components/Header/Header";
import {
  post_incomeBy_TransactionType,
  post_transaction_overview,
  post_userTransactionHistory,
} from "../../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import { useDispatch } from "react-redux";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

function Transaction() {
  const items = ["Today", "This Week", "Last Week", "This Month", "All Time"];
  const [sortOrder, setSortOrder] = useState("asc");
  const statusDropdownItem = ["All", "Failed", "Successful"];
  const statusDropdownTypeTran = [
    "All",
    "UPI",
    "Net banking",
    "Credit/Debit card",
    "Other wallet",
  ];
  const [searchValue, setSearchValue] = useState("");
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [allTransactionHistoryData, setAllTransactionHistoryData] = useState(
    []
  );
  const [transactionOverviewData, setTransactionOverviewData] = useState();
  const [transactionChartData, setTransactionChartData] = useState();
  const [totalPAgeCOunt, setTotalPageCount] = useState();
  const [doughtChartData, setDoughtChartData] = useState();
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");
  const [transactionDropdownLabel, setTransactionTypeDropDownSelectedValue] =
    useState("Transaction Type");

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    onApiCallTransactionOverview("Today");
    onApiCallTransactionChartData("Today");
  }, []);

  useEffect(() => {
    setPageComments(0);
    setTransactionHistoryData([]);
    setAllTransactionHistoryData([]);
    onApiCallTransactionListMainApi(
      transactionDropdownLabel,
      dropdownSelected,
      0
    );
  }, [transactionDropdownLabel, dropdownSelected]);

  const onApiCallTransactionListMainApi = (item, item2, pageComments) => {
    const paymentMethodMap = {
      "UPI": "upi",
      "Net banking": "netbanking",
      "Credit/Debit card": "card",
      "Other wallet": "wallet",
    };
    const statusMap = {
      Failed: "failed",
      Successful: "success",
    };

    const paymentMethodPass = paymentMethodMap[item] || "";
    const transactionStatus = statusMap[item2] || "";

    const param = {
      offset: 30 * pageComments,
      limit: 30,
      paymentMethod: paymentMethodPass,
      transactionStatus: transactionStatus,
    };

    dispatch(setLoadingTrue());
    post_userTransactionHistory(param)
      .then((response) => {
        setSearchValue("");
        setTransactionHistoryData((prevData) => [
          ...prevData,
          ...response?.data,
        ]);
        setAllTransactionHistoryData((prevData) => [
          ...prevData,
          ...response?.data,
        ]);

        setTotalPageCount(Math.ceil(response.totalTransactionsCount / 30));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onApiCallTransactionChartData = (type) => {
    try {
      const param = {
        filterType:
          type === "Today"
            ? "today"
            : type === "This Week"
            ? "thisWeek"
            : type === "Last Week"
            ? "lastWeek"
            : type === "This Month"
            ? "thisMonth"
            : "allTime",
      };
      dispatch(setLoadingTrue());
      post_incomeBy_TransactionType(param).then((response) => {
        const userChartData = {
          labels: ["UPI", "Net banking", "Credit/Debit card", "Other wallet"],
          datasets: [
            {
              label: "",
              data: [
                response?.upi_amount,
                response?.netBanking_amount,
                response?.card_amount,
                response?.other_amount,
              ],
              backgroundColor: [
                Colors.darkGreen,
                Colors.darkOrange,
                Colors.darkBlue,
                Colors.darkRed,
              ],
              borderColor: Colors.black,
              borderWidth: 1,
            },
          ],
        };
        setDoughtChartData(userChartData);

        setTransactionChartData(response);
        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };
  const onApiCallTransactionOverview = (type) => {
    try {
      const param = {
        filterType:
          type === "Today"
            ? "today"
            : type === "This Week"
            ? "thisWeek"
            : type === "Last Week"
            ? "lastWeek"
            : type === "This Month"
            ? "thisMonth"
            : "allTime",
      };
      dispatch(setLoadingTrue());
      post_transaction_overview(param).then((response) => {
        setTransactionOverviewData(response.data);

        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const handleSelect = (dateTime, transactionType) => {
    setSearchValue("");
  };

  const sortByAMount = (property) => {
    let keyString = "";
    if (property == "TotalAmount") {
      keyString = "totalAmount";
    } else if (property == "Tax") {
      keyString = "add_wallet_gst_amount";
    } else if ("Tra.Amount") {
      keyString = "add_wallet_amount";
    }

    const sortedData = [...transactionHistoryData];
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    sortedData.sort((a, b) => {
      if (newSortOrder === "asc") {
        return a[keyString] - b[keyString];
      } else {
        return b[keyString] - a[keyString];
      }
    });
    setTransactionHistoryData(sortedData);
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = allTransactionHistoryData.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setTransactionHistoryData(newData);
    } else {
      setTransactionHistoryData(allTransactionHistoryData);
    }
  };
  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  const TaxAmooutSort = () => {};

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalPAgeCOunt > pageComments + 1) {
        setPageComments((prevPage) => {
          const nextPage = prevPage + 1;
          onApiCallTransactionListMainApi(
            transactionDropdownLabel,
            dropdownSelected,
            nextPage
          );
          return nextPage;
        });
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Transaction/User transactions"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="sectionDivStyle">
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              borderRadius: "16px",
              backgroundColor: "#25252d",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "1rem 0rem 0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0rem 1rem",
                  gap: "1rem",
                }}
              >
                <div className="contentTitle24Weight40">Overview</div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.38rem 0.75rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={items}
                    onSelect={(item) => onApiCallTransactionOverview(item)}
                  />
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  fontSize: "1rem",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total amount
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                    className="title24"
                  >
                    ₹ {transactionChartData?.totalAmount}
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "0.06rem",
                  }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Transaction amount
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "white",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                    className="title24"
                  >
                    ₹ {transactionOverviewData?.transactionAmount}
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "0.06rem",
                  }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem 0rem",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Tax amount (18%)
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "white",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                    className="title24"
                  >
                    ₹ {transactionOverviewData?.gstAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              alignSelf: "stretch",
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="contentTitle24Weight40"
                  style={{ flex: "1", position: "relative" }}
                >
                  Transaction type
                </div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.38rem 0.75rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={items}
                    onSelect={(item) => onApiCallTransactionChartData(item)}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",

                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "12rem",
                  alignItems: "center",
                  display: "flex",
                  zIndex: 1,
                  height: "12rem",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "90%",
                    width: "90%",
                    zIndex: -1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="lastWeekEndDiv"
                    style={{ fontWeight: "600", color: Colors.secondary }}
                  >
                    Total
                  </div>
                  <div
                    className="astrologerText"
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    ₹ {transactionChartData?.totalAmount}
                  </div>
                </div>
                {doughtChartData && (
                  <div
                    style={{
                      height: "90%",
                      width: "90%",
                    }}
                  >
                    <Doughnut
                      options={{
                        cutout: "75%",
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                      data={doughtChartData}
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  flex: "1",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  rowGap: "20px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignSelf: "stretch",
                    alignItems: "baseline",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkGreen,
                      width: "0.7rem",
                      height: "0.7rem",
                      left: "17px",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    <span style={{ marginLeft: "15px" }}> UPI</span>
                    <br />
                    <div
                      style={{ fontWeight: "400" }}
                      className="lastWeekEndDiv"
                    >
                      ₹ {transactionChartData?.upi_amount}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    alignSelf: "stretch",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkOrange,
                      width: "0.7rem",
                      height: "0.7rem",
                      left: "17px",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      marginLeft: "8px",
                      color: Colors.secondary,
                      fontWeight: "400",
                    }}
                    className="lastWeekEndDiv"
                  >
                    <span style={{ marginLeft: "15px" }}> Net banking</span>
                    <br />
                    <div
                      style={{ fontWeight: "400" }}
                      className="lastWeekEndDiv"
                    >
                      ₹ {transactionChartData?.netBanking_amount}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    alignSelf: "stretch",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkBlue,
                      width: "0.7rem",
                      height: "0.7rem",
                      left: "17px",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    <span style={{ marginLeft: "15px" }}>
                      Credit/Debit card
                    </span>
                    <br />
                    <div
                      style={{ fontWeight: "400" }}
                      className="lastWeekEndDiv"
                    >
                      ₹ {transactionChartData?.card_amount}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    alignSelf: "stretch",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkRed,
                      width: "0.7rem",
                      height: "0.7rem",
                      left: "17px",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    <span style={{ marginLeft: "15px" }}> Other wallet</span>
                    <br />
                    <div
                      style={{ fontWeight: "400" }}
                      className="lastWeekEndDiv"
                    >
                      ₹ {transactionChartData?.other_amount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionDivStyle">
          <div
            className="title24FontWeight400WHite"
            style={{
              flex: "1",
              position: "relative",
              alignSelf: "stretch",
              alignItems: "center",
              display: "flex",
            }}
          >
            Transaction history
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              fontSize: "1rem",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "12rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={transactionDropdownLabel}
                items={statusDropdownTypeTran}
                color="blue"
                onSelect={(item) => {
                  setTransactionTypeDropDownSelectedValue(item);
                }}
                customLabelStyle={{
                  fontWeight: "400",
                  color:
                    // transactionDropdownLabel == "Transaction Type"
                    //   ?
                    Colors.greyColor50,
                  // : Colors.white,
                }}
              />
            </div>

            <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "9.38rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={dropdownSelected}
                items={statusDropdownItem}
                color="blue"
                onSelect={(item) => setDropDownSelectedValue(item)}
                customLabelStyle={{
                  fontWeight: "400",
                  color: Colors.greyColor50,
                }}
              />
            </div>

            <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "9.38rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={"All time"}
                items={items}
                onSelect={handleSelect}
              />
            </div>

            <div
              style={{
                borderRadius: "8px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                boxSizing: "border-box",
                width: "15.75rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0.5rem 0.5rem",
                gap: "0.5rem",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "1.5rem",
                  height: "1.5rem",
                  objectFit: "contain",
                }}
                alt=""
                src={Icons.ic_search}
              />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <Usertransactions
          handleScroll={handleScroll}
          commentsContainerRef={commentsContainerRef}
          data={transactionHistoryData}
          transactionID={"Transaction ID"}
          User={"User"}
          Offer={"Offer"}
          Transactionamount={`Tra. Amount`}
          Taxamount={"Tax amount (18%)"}
          TaxAmooutSort={TaxAmooutSort}
          dateTime={"Date & Time"}
          status={"Status"}
          amountSort={(item) => sortByAMount(item)}
          amount={"Total amount"}
        />

        {transactionHistoryData.length <= 0 && <EmptyData />}
        {/* {transactionHistoryData.length != 0 && (
          <div
            style={{
              alignItems: "flex-end",
              display: "flex",
              flex: 1,
              alignSelf: "stretch",
              justifyContent: "flex-end",
            }}
          >
            <CustomPagination
              pageCount={totalPAgeCOunt}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={onApiCallTransactionListMainApi}
            />
          </div>
        )} */}
      </div>
    </div>
  );
}
export default Transaction;

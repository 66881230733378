import React, { useCallback, useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import Icons from "../../../Constants/Icons";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Header from "../../../Components/Header/Header";
import "./CouponScreenStyle.css";
import StatusButton from "../../../Components/StatusButton/StatusButton";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import {
  apiCallCouponEditCoupon,
  apiCallCouponUpdateCouponStatus,
  postAPiCAllCouponCouponOverview,
  postAPiCallAddNewCOuponCOde,
  postApiCallCOuponCodeList,
} from "../../../Core/Apicall";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";

const customStyles = {
  overlay: {
    zIndex: 11,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    padding: 0,
    margin: "auto",
    maxWidth: "400px",
    width: "100%",
    border: "none",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
const itemDropdown = ["All", "Wallet", "Product"];
function Coupon() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsMenuOpen] = useState(false);
  const [secondClockOPen, setToSecondOPenClock] = useState(false);
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [fromTimeOPen, setFromTimeOpen] = useState(false);
  const [isValidityShow, setIsValidityShow] = useState(false);
  const [editCouponDetails, setEditCouponDetails] = useState(false);
  const [couponOverViewDetails, setCouponOverview] = useState();
  const [couponListData, setCouponListdata] = useState([]);
  const [couponListAllData, setCouponListAlldata] = useState([]);
  const [totalCOuponCodeCOunt, setTotalCouponCOdeTotal] = useState(0);
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");
  // const [initialPage, setInitialPage] = useState(0);

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  const [formData, setFormData] = useState({
    couponCode: "",
    couponType: "",
    isValidityToDate: new Date(),
    isfromDatepen: new Date(),
    // isfromDatepen: new Date(),
    validityTimeClock: dayjs(new Date()),
    toSecondClockTime: dayjs(new Date()),
    maximumUse: "",
    discount_per: "",
    description: "",
    couponId: "",
  });

  const [formErrors, setFormErrors] = useState({
    couponCode: false,
    couponType: false,
    isValidityToDate: false,
    isfromDatepen: false,
    validityTimeClock: false,
    toSecondClockTime: false,
    maximumUse: false,
    discount_per: false,
    description: false,
  });

  useEffect(() => {
    counponOverView();
    couponListApiCall();
  }, [pageComments]);

  const onApiCallCouponStatusChange = async (status, item) => {
    try {
      const param = {
        couponId: item?._id,
        type: status == "Active" ? "active" : "inActive",
      };
      dispatch(setLoadingTrue());
      apiCallCouponUpdateCouponStatus(param)
        .then((response) => {
          closeMenu();
          counponOverView();
          const updateCoupon = couponListData?.map((data) => {
            if (item?._id === data._id) {
              return { ...data, isActive: !data.isActive };
            }
            return data;
          });
          setCouponListdata(updateCoupon);
          setCouponListAlldata(updateCoupon);
          dispatch(setLoadingFalse());
        })
        .catch((err) => dispatch(setLoadingFalse()));
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };
  const couponListApiCall = async () => {
    try {
      const param = {
        offset: 30 * pageComments,
        limit: 30,
      };

      dispatch(setLoadingTrue());
      postApiCallCOuponCodeList(param)
        .then((response) => {
          setSearchValue("");
          setDropDownSelectedValue("All");
          // setInitialPage(selectedPage?.selected || 0);
          // setCouponListdata(response?.coupons);
          // setCouponListAlldata(response?.coupons);

          setCouponListdata(
            pageComments == 0
              ? response?.coupons
              : (prevData) => [...prevData, ...response?.coupons]
          );
          setCouponListAlldata(
            pageComments == 0
              ? response?.coupons
              : (prevData) => [...prevData, ...response?.coupons]
          );

          setTotalCouponCOdeTotal(Math.ceil(response.totalCouponCount / 30));
          dispatch(setLoadingFalse());
        })
        .catch((err) => dispatch(setLoadingFalse()));
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const counponOverView = async () => {
    try {
      dispatch(setLoadingTrue());
      postAPiCAllCouponCouponOverview()
        .then((response) => {
          setCouponOverview(response?.data);
          dispatch(setLoadingFalse());
        })
        .catch((err) => dispatch(setLoadingFalse()));
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const handleSelect = (status) => {
    setSearchValue("");
    setDropDownSelectedValue(status);
    if (status === "All") {
      setCouponListdata(couponListAllData);
    } else {
      const filteredData = couponListAllData.filter(
        (item) => item.type === status
      );
      setCouponListdata(filteredData);
    }
  };

  const handleChange = useCallback((newChecked) => {
    setChecked(newChecked);
  }, []);
  const toggleMenu = (index) => {
    if (index === openMenuIndex) {
      setOpenMenuIndex(-1);
    } else {
      setOpenMenuIndex(index);
    }
  };
  const closeModal = () => {
    document.body.style.overflow = "unset";
    setIsMenuOpen(false);
    clearData();
  };

  const closeDatePicker = () => {
    setOpen(false);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
    setOpenMenuIndex(-1);
  };

  const handleDropdownItemClick = (item) => {
    setIsOpen(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      couponType: item,
    }));
  };

  const handleMouseDropdownLeave = () => {
    setIsOpen(false);
  };

  const onClickEditCoupon = async (item) => {
    document.body.style.overflow = "hidden";
    setEditCouponDetails(true);
    closeMenu();
    setChecked(item?.isActive);
    setFormData({
      couponId: item._id,
      couponCode: item.code,
      couponType: item?.type,
      isValidityToDate: new Date(item?.validityTo),
      isfromDatepen: new Date(item?.validityFrom),
      validityTimeClock: dayjs(new Date(item?.validityFrom)),
      toSecondClockTime: dayjs(new Date(item?.validityTo)),
      maximumUse: item?.maximumUse,
      discount_per: item?.discountPercentage,
      description: item?.description,
    });
    setIsMenuOpen(true);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalCOuponCodeCOunt > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  const renderCouponContent = () => {
    return (
      <div className="list-user-div-parent-container-coupon">
        <div
          style={{
            width: "15%",
          }}
          className="fontSize16WithoutFlex"
        >
          Coupon code ID
        </div>
        <div
          style={{
            width: "10%",
          }}
          className="fontSize16WithoutFlex"
        >
          Coupon code
        </div>
        <div
          style={{
            width: "10%",
          }}
          className="fontSize16WithoutFlex"
        >
          Created on
        </div>
        <div
          style={{
            width: "15%",
          }}
          className="fontSize16WithoutFlex"
        >
          Code type
        </div>
        <div
          style={{
            width: "15%",
          }}
          className="fontSize16WithoutFlex"
        >
          Validity
        </div>
        <div
          style={{
            width: "18%",
          }}
          className="fontSize16WithoutFlex"
        >
          Description
        </div>
        <div
          style={{
            width: "7%",

            // textAlign: "center",
          }}
          className="fontSize16WithoutFlex"
        >
          Max. use
        </div>
        <div
          style={{
            width: "7%",
          }}
          className="fontSize16WithoutFlex"
        >
          Used
        </div>
        <div
          style={{
            width: "9%",
          }}
          className="fontSize16WithoutFlex"
        >
          Status
        </div>
        <div
          style={{
            width: "2%",
          }}
        ></div>
        {/* <div style={{ width: "3px" }}></div> */}
      </div>
    );
  };

  const renderCouponList = () => {
    return (
      <div
        style={{ maxHeight: "500px" }}
        ref={commentsContainerRef}
        onScroll={handleScroll}
        className="scrollable-container"
      >
        {couponListData.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: "stretch",
                  padding: "1rem",
                  boxSizing: "border-box",
                  gap: "0.5rem",
                }}
              >
                <div
                  style={{
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",

                    fontSize: "1.13rem",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "0.8rem",
                      fontWeight: 400,
                      color: "#fff",
                      width: "55%",
                      wordBreak: "break-all",
                    }}
                  >
                    {item?._id}
                  </div>
                  <img
                    onClick={() => navigator.clipboard.writeText(item?._id)}
                    style={{
                      position: "relative",
                      width: "1.13rem",
                      height: "1.13rem",
                      overflow: "hidden",
                      flexShrink: "0",
                      cursor: "pointer",
                    }}
                    alt=""
                    src={Icons.ic_copy}
                  />
                </div>
                <div
                  style={{
                    width: "10%",
                  }}
                  className="itemTextDisplay"
                >
                  {item.code}
                </div>
                <div
                  style={{
                    width: "10%",
                  }}
                  className="itemTextDisplay"
                >
                  {moment(item?.createdAt).format("DD-MM-YYYY")}
                </div>
                <div
                  style={{
                    width: "15%",
                    borderRadius: "20px",
                    height: "1.8rem",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    border: Colors.darkBorderColor,
                  }}
                  className="itemTextDisplay"
                >
                  {item.type}
                </div>
                <div
                  style={{
                    width: "15%",
                  }}
                  className="itemTextDisplay"
                >
                  From: {moment(item?.validityFrom).format("DD-MM-YYYY") + ","}
                  <br /> {moment(item?.validityFrom).format("hh:mm a")}
                  <br />
                  To: {moment(item?.validityTo).format("DD-MM-YYYY") + ","}
                  <br />
                  {moment(item?.validityTo).format("hh:mm a")}
                </div>
                <div
                  style={{
                    width: "18%",
                  }}
                  className="itemTextDisplay"
                >
                  {item?.description}
                  {" " + item.discountPercentage + "%"}
                </div>
                <div
                  style={{
                    width: "7%",
                  }}
                  className="itemTextDisplay"
                >
                  {item.maximumUse}
                </div>
                <div
                  style={{
                    width: "7%",
                  }}
                  className="itemTextDisplay"
                >
                  {item.totalUsedCount}
                </div>
                <StatusButton
                  style={{
                    width: "9%",
                    backgroundColor: !item.isActive
                      ? Colors.mainRed01
                      : Colors.mainGreen01,
                    borderColor: !item.isActive
                      ? Colors.darkRed
                      : Colors.darkGreen,
                    color: !item.isActive ? Colors.darkRed : Colors.darkGreen,
                  }}
                >
                  {item.isActive ? "Active" : "InActive"}
                </StatusButton>
                <div
                  onClick={() => toggleMenu(index)}
                  style={{ cursor: "pointer", width: "2%", display: "flex" }}
                >
                  <img
                    style={{ width: "1.2rem", objectFit: "cover" }}
                    src={Icons.ic_more}
                  />
                </div>
                <div
                  style={{
                    marginBottom:
                      index === couponListData.length - 1 ? "80px" : "auto",
                    right: "1rem",
                    top: index == 0 ? "13px" : "auto",
                  }}
                  className="custom-menu-popup-container"
                  // className="custom-menu-popup-container"
                >
                  {openMenuIndex === index && (
                    <div onMouseLeave={closeMenu} className="custom-menu-popup">
                      {!item.isActive ? (
                        <div
                          onClick={() =>
                            onApiCallCouponStatusChange("Active", item)
                          }
                          className="custom-menu-list"
                        >
                          Active
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            onApiCallCouponStatusChange("InActive", item)
                          }
                          className="custom-menu-list"
                        >
                          InActive
                        </div>
                      )}

                      <div
                        onClick={() => onClickEditCoupon(item)}
                        className="custom-menu-list"
                      >
                        Edit
                      </div>
                      <div className="custom-menu-list">Delete</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="borderTopStyle" />
            </div>
          );
        })}
      </div>
    );
  };

  const handleValidityTimeChange = (newTime) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      validityTimeClock: newTime,
    }));
    setFromTimeOpen(false);
  };
  const handleToTimeChange = (newTime) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      toSecondClockTime: newTime,
    }));
    setToSecondOPenClock(false);
  };

  const handleInputChange = (e, fieldName) => {
    let value = e.target.value;
    if (fieldName == "maximumUse" || fieldName == "discount_per") {
      value = value.replace(/[^0-9]/g, "");
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const validateForm = () => {
    const errors = {
      couponCode: formData.couponCode == "",
      couponType: formData.couponType == "",
      maximumUse: formData?.maximumUse == "",
      discount_per: formData?.discount_per == "",
      description: formData?.description == "",
      // isValidityToDate:
      //   moment(formData?.isValidityToDate).format("YYYY-MM-DDT") +
      //     moment(new Date(formData?.toSecondClockTime)).format(
      //       "HH:mm:ss.SSSZ"
      //     ) ==
      //   moment(formData?.isfromDatepen).format("YYYY-MM-DDT") +
      //     moment(new Date(formData?.validityTimeClock)).format("HH:mm:ss.SSSZ"),
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const clearData = () => {
    setEditCouponDetails(false);
    setFormErrors({
      couponCode: false,
      couponType: false,
      isValidityToDate: false,
      isfromDatepen: false,
      validityTimeClock: false,
      toSecondClockTime: false,
      maximumUse: false,
      discount_per: false,
      description: false,
    });
    setFormData({
      couponId: "",
      couponCode: "",
      couponType: "",
      isValidityToDate: new Date(),
      isfromDatepen: new Date(),
      validityTimeClock: dayjs(new Date()),
      toSecondClockTime: dayjs(new Date()),
      maximumUse: "",
      discount_per: "",
      description: "",
    });
    setChecked(false);
  };

  const onClickCreateCoupon = async () => {
    if (validateForm()) {
      dispatch(setLoadingTrue());
      let param = {
        CouponCode: formData?.couponCode,
        CouponType: formData?.couponType,
        ValidityTo:
          moment(formData?.isValidityToDate).format("YYYY-MM-DDT") +
          moment(new Date(formData?.toSecondClockTime)).format("HH:mm:ss.SSSZ"),

        ValidityFrom:
          moment(formData?.isfromDatepen).format("YYYY-MM-DDT") +
          moment(new Date(formData?.validityTimeClock)).format("HH:mm:ss.SSSZ"),
        MaximumUse: formData?.maximumUse,
        DiscountPercentage: formData?.discount_per,
        Description: formData?.description,
        isActive: checked,
      };
      try {
        if (editCouponDetails) {
          param.couponId = formData.couponId;
          let res = await apiCallCouponEditCoupon(param);
          const updateCoupon = couponListData?.map((data) => {
            if (formData.couponId === data._id) {
              return {
                ...data,
                code: res?.coupon?.CouponCode,
                type: res?.coupon?.CouponType,
                createdAt: res.coupon?.createdAt,
                validityTo: res.coupon?.ValidityTo,
                validityFrom: res.coupon?.ValidityFrom,
                maximumUse: res.coupon?.MaximumUse,
                discountPercentage: res.coupon?.DiscountPercentage,
                description: res.coupon?.Description,
                isActive: res.coupon?.isActive,
              };
            }
            return data;
          });
          setCouponListdata(updateCoupon);
          setCouponListAlldata(updateCoupon);
        } else {
          await postAPiCallAddNewCOuponCOde(param);
          if (pageComments == 0) {
            counponOverView();
            couponListApiCall();
          } else {
            setPageComments(0);
          }
        }

        document.body.style.overflow = "unset";
        clearData();
        setIsMenuOpen(false);
        dispatch(setLoadingFalse());
      } catch (error) {
        dispatch(setLoadingFalse());
      }
    }
  };

  const handleInputSearch = (e) => {
    setDropDownSelectedValue("All");
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = couponListAllData.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setCouponListdata(newData);
    } else {
      setCouponListdata(couponListAllData);
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Coupon codes"} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
        }}
      >
        <div style={{}} className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total coupon codes</div>
                <div className="fontSize24">
                  {couponOverViewDetails?.totalCouponCount}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Active</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {couponOverViewDetails?.activeCouponCount}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Inactive</div>
                <div style={{ color: Colors.darkRed }} className="fontSize24">
                  {couponOverViewDetails?.inActiveCouponCount}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div
                  style={{
                    display: "flex",
                    alignSelf: "stretch",
                  }}
                  className="fontSize16"
                >
                  New added{" "}
                  <div
                    style={{ textAlign: "end", color: Colors.secondary }}
                    className="fontSize16"
                  >
                    Last 7 days
                  </div>
                </div>
                <div style={{ color: Colors.darkBlue }} className="fontSize24">
                  {couponOverViewDetails?.newCouponCount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="astrologers-list-parent"
      >
        <div
          // onClick={() => navigate("/UserProfile")}
          // style={{ cursor: "pointer" }}
          className="fontSize24"
        >
          Coupon code list
        </div>
        <div className="frame-parent2">
          <div className="custom-box-user">
            <div className="custom-container-dropdown-div">
              <CustomDropdown
                dropDownLabel={dropdownSelected}
                items={itemDropdown}
                onSelect={handleSelect}
                color="blue"
                customLabelStyle={{
                  fontWeight: "400",
                  color: Colors.greyColor50,
                }}
              />
            </div>

            <div className="custom-box-search-div">
              <img className="custom-image" alt="" src={Icons.ic_search} />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputSearch}
              />
            </div>
            <div
              onClick={() => {
                document.body.style.overflow = "hidden";
                setIsMenuOpen(true);
              }}
              style={{
                cursor: "pointer",
                background: Colors.white,
                padding: "0.7rem 0.7rem",
                gap: "0.5rem",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="darkGreyFont16" style={{ color: Colors.black }}>
                Create Coupon
              </div>
              <img
                src={Icons.ic_add}
                style={{ height: "1rem", width: "1rem", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="list-user-div-container">
        {renderCouponContent()}
        {renderCouponList()}
      </div>
      {couponListData.length <= 0 && <EmptyData />}
      {/* {couponListData.length != 0 && (
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            pageCount={totalCOuponCodeCOunt}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={couponListApiCall}
          />
        </div>
      )} */}
      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isModalOpen}
        // onRequestClose={closeModal}
      >
        <div
          className="scrollable-container"
          style={{
            maxHeight: "29rem",
            width: "90%",
            background: "#393942",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          {fromTimeOPen && (
            <div
              style={{
                position: "absolute",
                width: "90%",
                height: "90%",
                alignSelf: "center",
                zIndex: 99099999,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticTimePicker
                  sx={{
                    borderRadius: 5,
                    margin: 0,
                    height: null,
                    width: null,
                  }}
                  ampm={false}
                  minTime={
                    moment(formData?.isfromDatepen).format("DD-MM-YYYY") ==
                    moment().format("DD-MM-YYYY")
                      ? dayjs(new Date())
                      : null
                  }
                  value={formData?.validityTimeClock}
                  onClose={() => setFromTimeOpen(false)}
                  onAccept={handleValidityTimeChange}
                />
              </LocalizationProvider>
            </div>
          )}
          {secondClockOPen && (
            <div
              style={{
                position: "absolute",
                width: "90%",
                height: "90%",
                alignSelf: "center",
                zIndex: 999999999,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticTimePicker
                  sx={{
                    borderRadius: 5,
                    margin: 0,
                    height: null,
                    width: null,
                  }}
                  ampm={false}
                  // minTime={formData?.validityTimeClock}

                  minTime={
                    moment(formData?.isfromDatepen).format("DD-MM-YYYY") ==
                    moment().format("DD-MM-YYYY")
                      ? dayjs(new Date())
                      : null
                  }
                  value={formData?.validityTimeClock}
                  onClose={() => setToSecondOPenClock(false)}
                  onAccept={handleToTimeChange}
                />
              </LocalizationProvider>
            </div>
          )}
          <div
            style={{
              display: "flex",
            }}
          >
            <div className="fontSize24">
              {editCouponDetails
                ? "Edit coupon code"
                : "Create new coupon code"}
            </div>
            <img
              onClick={() => closeModal()}
              style={{ cursor: "pointer" }}
              src={Icons.ic_close}
              alt="ic_close"
              className="modal-ic_close"
            />
          </div>

          <div style={{ paddingTop: "6px" }} className="modal-main-div">
            <div className="fontSize16">Coupon code</div>
            <input
              className="modal-main-input"
              type="text"
              style={{
                border: formErrors.couponCode
                  ? "1px solid red"
                  : "1px solid rgba(255, 255, 255, 0.2)",
              }}
              placeholder="Enter code"
              value={formData?.couponCode}
              onChange={(e) => handleInputChange(e, "couponCode")}
            />
          </div>

          <div className="modal-second-div-title">
            <div className="fontSize16">Coupon code type</div>

            <div style={{}}>
              <div
                style={{
                  padding: "5px 10px 5px 10px",
                  border: formErrors.couponType
                    ? "1px solid red"
                    : "1px solid rgba(255, 255, 255, 0.2)",
                }}
                className="modal-coupon-code-type"
              >
                <CustomDropdown
                  customLabelStyle={{
                    color: formData?.couponType
                      ? "#FFF"
                      : "#FFFFFF33",
                    // fontSize: "0.75rem",
                    fontWeight: "normal",
                  }}
                  dropDownLabel={
                    formData?.couponType == ""
                      ? "Select coupon code type"
                      : formData?.couponType
                  }
                  items={["Wallet", "Product"]}
                  onSelect={(value) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      couponType: value,
                    }));
                  }}
                />
              </div>
            </div>

            {/* <div className="modal-coupon-code-type">
              <input
                className="modal-dropdown"
                type="text"
                placeholder="input text"
                value={couponType}
                onChange={(event) => setCouponType(event.target.value)}
              />
              <div
                onClick={() => toggleDropdown()}
                style={{ cursor: "pointer", flex: 0.1, display: "flex" }}
              >
                <img
                  src={Icons.ic_grey_down}
                  style={{ height: "1rem", width: "1rem" }}
                />
              </div>
            </div> */}
            {isOpen && (
              <div
                onMouseLeave={() => handleMouseDropdownLeave()}
                style={{
                  zIndex: 9,
                  right: 0,
                }}
              >
                <div style={{ height: 10 }}></div>
                <ul style={{ right: "1rem" }} className="dropdown-menu">
                  {itemDropdown.map((item) => (
                    <li
                      key={item}
                      onClick={() => handleDropdownItemClick(item)}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="modal-second-div-title">
            <div className="fontSize16">Validity from</div>

            <div className="date-display-flex">
              <div
                style={{ zIndex: open ? 9999999999999 : 1 }}
                className="date-outside-div"
                onClick={() => setOpen(true)}
              >
                <DatePicker
                  showPopperArrow={false}
                  onClickOutside={() => closeDatePicker()}
                  onChange={(date) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      isfromDatepen: date,
                      isValidityToDate: date,
                      toSecondClockTime: dayjs(new Date(date)),
                    }))
                  }
                  minDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                  customInput={
                    <div className="date-picker-date-show">
                      <img
                        src={Icons.ic_grey_calendar}
                        className="modal-from-to-icon"
                      />
                      <div className="fontSize16">
                        {formData?.isfromDatepen.toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </div>
                    </div>
                  }
                  selected={formData?.isfromDatepen}
                  open={open}
                  style={{
                    border: "2px solid #3498db",
                    borderRadius: "5px",
                    padding: "5px",
                    backgroundColor: "red",
                    color: "black",
                  }}
                  onBlur={() => closeDatePicker()}
                />
              </div>
              <div
                className="date-picker-main-div"
                onClick={() => setFromTimeOpen(true)}
              >
                <div
                  onClick={() => setFromTimeOpen(true)}
                  className="date-picker-date-show"
                >
                  <img
                    src={Icons.ic_grey_watch}
                    className="modal-from-to-icon"
                  />
                  <div className="fontSize16">
                    {formData?.validityTimeClock.format("hh:mm A")}{" "}
                  </div>
                </div>
              </div>
            </div>

            {formErrors.isValidityToDate && (
              <div style={{ fontSize: "0.77rem", color: "red" }}>
                {"Select current date"}
              </div>
            )}
          </div>

          <div className="modal-second-div-title">
            <div className="fontSize16">Validity upto</div>
            <div className="date-display-flex">
              <div
                style={{ zIndex: 999999 }}
                className="date-outside-div"
                onClick={() => setIsValidityShow(true)}
              >
                <DatePicker
                  showPopperArrow={false}
                  onClickOutside={() => setIsValidityShow(false)}
                  onChange={(date) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      isValidityToDate: date,
                    }))
                  }
                  // minDate={new Date()}
                  minDate={
                    new Date(formData?.isfromDatepen)
                    // moment(new Date()).add(1, 'days')
                    // formData?.isfromDatepen
                  }
                  // moment(formData?.isfromDatepen).format("DD-MM-YYYY") ==
                  //   moment().format("DD-MM-YYYY")
                  //     ? dayjs(new Date())
                  //     : null
                  dateFormat="dd-MM-yyyy"
                  customInput={
                    <div className="date-picker-date-show">
                      <img
                        src={Icons.ic_grey_calendar}
                        className="modal-from-to-icon"
                      />
                      <div className="fontSize16">
                        {formData?.isValidityToDate.toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </div>
                    </div>
                  }
                  selected={formData?.isValidityToDate}
                  open={isValidityShow}
                  style={{
                    border: "2px solid #3498db",
                    borderRadius: "5px",
                    padding: "5px",
                    backgroundColor: "red",
                    color: "black",
                  }}
                  onBlur={() => setIsValidityShow(false)}
                />
              </div>
              {/* <div
                className="date-picker-main-div"
                onClick={() => setIsFromOpen(true)}
              >
                <DatePicker
                  showPopperArrow={false}
                  onClickOutside={() => setIsFromOpen(false)}
                  onChange={(date) => setFromTime(date)}
                  minDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                  customInput={
                    <div className="date-picker-date-show">
                      <img
                        src={Icons.ic_grey_watch}
                        className="modal-from-to-icon"
                      />
                      <div className="fontSize16">09:00 AM</div>
                    </div>
                  }
                  selected={isFromTime}
                  open={isFromOpen}
                  style={{
                    border: "2px solid #3498db",
                    borderRadius: "5px",
                    padding: "5px",
                    backgroundColor: "red",
                    color: "black",
                  }}
                  onBlur={() => setIsFromOpen(false)}
                />
              </div> */}
              <div
                className="date-picker-main-div"
                onClick={() => setToSecondOPenClock(true)}
              >
                <div
                  onClick={() => setToSecondOPenClock(true)}
                  className="date-picker-date-show"
                >
                  <img
                    src={Icons.ic_grey_watch}
                    className="modal-from-to-icon"
                  />
                  <div className="fontSize16">
                    {formData?.toSecondClockTime.format("hh:mm A")}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-second-div-title">
            <div className="fontSize16">Maximum use</div>
            <input
              className="modal-main-input"
              type="text"
              maxLength={3}
              style={{
                border: formErrors.maximumUse
                  ? "1px solid red"
                  : "1px solid rgba(255, 255, 255, 0.2)",
              }}
              placeholder="Enter maximum use"
              value={formData?.maximumUse}
              onChange={(e) => handleInputChange(e, "maximumUse")}
            />
          </div>
          <div className="modal-second-div-title">
            <div className="fontSize16">Discount in Percentage</div>
            
            <input
              className="modal-main-input"
              type="text"
              style={{
                border: formErrors.discount_per
                  ? "1px solid red"
                  : "1px solid rgba(255, 255, 255, 0.2)",
              }}
              placeholder="e.g. 10"
              value={formData?.discount_per}
              onChange={(e) => handleInputChange(e, "discount_per")}
            />
            
          </div>

          <div className="modal-second-div-title">
            <div
              style={{ display: "flex", gap: "0.3rem", alignItems: "center" }}
              className="fontSize16"
            >
              Description
              {/* <div
                style={{
                  color: Colors.secondary,
                  fontSize: "0.6rem",
                }}
                className="fontSize16"
              >
                0/50
              </div> */}
            </div>
            <input
              className="modal-main-input"
              type="text"
              maxLength={250}
              style={{
                border: formErrors.description
                  ? "1px solid red"
                  : "1px solid rgba(255, 255, 255, 0.2)",
              }}
              placeholder="Enter description"
              value={formData?.description}
              onChange={(e) => handleInputChange(e, "description")}
            />
          </div>

          <div className="modal-main-div">
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Switch
                checked={checked}
                onChange={handleChange}
                onColor={Colors.darkGreen}
                offColor="#818181"
                onHandleColor="#818181"
                // onHandleColor="rgba(129, 129, 129, 1)"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={40}
                className="react-switch"
                id="material-switch"
              />
              <div className="fontSize16">Active this coupon code</div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              gap: "1rem",
              paddingTop: "1rem",
            }}
          >
            <div
              onClick={() => closeModal()}
              style={{ cursor: "pointer" }}
              className="fontSize16 modal-cancel-button"
            >
              Cancel
            </div>
            <div
              onClick={() => onClickCreateCoupon()}
              style={{ cursor: "pointer" }}
              className="fontSize16 modal-create-btn"
            >
              Create coupon
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Coupon;

import React from "react";
import Colors from "../../../../Constants/Colors";
import "./../../../../Constants/CommonStyle.css";
import "../AstrologerStyle.css";
import Icons from "../../../../Constants/Icons";
import StatusButton from "../../../../Components/StatusButton/StatusButton";

import { useNavigate } from "react-router-dom";



function AstrologerRenderList({
  session,
  index,
  name,
  profession,
  ratings,
  chatsessions,
  price,
  status,
  avg,
  liveSession,
  totalSession,
  callsessions,
  profileUrl,
  astrologerId,
  expertise
}) {
  const navigate = useNavigate();

  return (
    <div
      key={index}
      style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}
      className="frame-parent-display"
    >
      <div
        onClick={() =>
          navigate("/AstrologerProfile", {
            state: {
              astrologerId: astrologerId,
            },
          })
        }
        style={{
          alignSelf: "stretch",
          cursor: "pointer",
          flex: 1,
        }}
        className="detailsShow"
      >
        <div style={{ width: "20%" }} className="frame-parent6">
          <div className="indian-man-smiling-astrologer">
            <img
              style={{
                position: "relative",
                width: "2.63rem",
                height: "2.63rem",
                objectFit: "contain",
              }}
              alt=""
              src={profileUrl ?? Icons.ic_default_user}
            />
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              fontSize: "1.13rem",
              color: Colors.white,
            }}
          >
            <div
              style={{
                fontFamily: "Poppins-Regular",
                fontSize: "0.8rem",
                position: "relative",
                fontWeight: 600,
              }}
            >
              {name}
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "0.8rem",
                fontWeight: "400",
                color: Colors.greyColor50,
              }}
            >
              {profession}
            </div>
          </div>
        </div>

        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "10%",
          }}
          className="star-parent"
        >
          <img className="down-icon" alt="" src={Icons.ic_star} />
          <div className="my-account">{ratings}</div>
        </div>

        <div
          style={{
            width: "8%",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          className="my-account"
        >
          {`₹${session?.fees ?? 0}/min`}
          <div className="ic_sorting" />
        </div>
        <div
          style={{
            width: "17%",
            justifyContent: "center",

            alignItems: "center",

          }}
          className="my-account"
        >
        
          {avg}
        </div>
        <div
          style={{
            gap: "1rem",
            width: "35%",
            display: "flex",
            flexDirection: "row-reverse",
            flexFlow: "wrap",
          }}
        >
          {expertise.map((item,index) => {
            return (
              <div
              key={index}
                style={{
                  borderRadius: "46px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  textAlign: "center",
                  padding: "5px 12px",
                }}
                className="my-account"
              >
                {item.expertise}
              </div>
            );
          })}
        </div>
        <StatusButton
          style={{
            width: "10%",
            backgroundColor:
              status === "online"
                ? Colors.mainGreen01
                : status === "away"
                ? Colors.mainOrange01
                : Colors.mainRed01,
            borderColor:
              status === "online"
                ? Colors.darkGreen
                : status === "away"
                ? Colors.darkOrange
                : Colors.darkRed,
            color:
              status === "online"
                ? Colors.darkGreen
                : status === "away"
                ? Colors.darkOrange
                : Colors.darkRed,
          }}
        >
          {status}
        </StatusButton>
      </div>
    </div>
  );
}

export default AstrologerRenderList;

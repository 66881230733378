import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
  Navigate,
  useLocation,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Colors from "./App/Constants/Colors";
import "./index.css";
import Icons from "./App/Constants/Icons";
import Home from "./App/Screens/MainScreen/Home/HomeScreen";
import Transaction from "./App/Screens/MainScreen/Transaction/Transaction";
import Astrologer from "./App/Screens/MainScreen/Astrologer/Astrologer";
import Registration from "./App/Screens/MainScreen/Registration/Registration";
import Shop from "./App/Screens/MainScreen/Shop/Shop";
import AstrologerProfile from "./App/Screens/MainScreen/Astrologer/AstrologerProfile";
import UserProfile from "./App/Screens/MainScreen/User/UserProfile";
import QuickSessionInfo from "./App/Components/QuickSessionInfo/QuickSessionInfo";
import Session from "./App/Screens/MainScreen/Sessions/Session";
import SessionLive from "./App/Screens/MainScreen/Sessions/SessionLive/SessionLive";
import { Collapse } from "@mui/material";
import Ongoing from "./App/Screens/MainScreen/Sessions/SessionSubList/Ongoing";
import Scheduled from "./App/Screens/MainScreen/Sessions/SessionSubList/Scheduled";
import Rejected from "./App/Screens/MainScreen/Sessions/SessionSubList/Rejected";
import Completed from "./App/Screens/MainScreen/Sessions/SessionSubList/Completed";
import Missed from "./App/Screens/MainScreen/Sessions/SessionSubList/Missed";
import LiveSession from "./App/Screens/MainScreen/Sessions/SessionSubList/LiveSession";
import SessionStatusDetails from "./App/Screens/MainScreen/Sessions/SessionLive/SessionStatusDetails";
import Coupon from "./App/Screens/MainScreen/Coupon/Coupon";
import Customer from "./App/Screens/MainScreen/Customer/Customer";
import Loader from "./App/Components/Loader/Loader";
import User from "./App/Screens/MainScreen/User/User";
import ScheduledSessionInfo from "./App/Components/ScheduleSessionInfo/ScheduledSessionInfo";
import SocketCall from "./App/Core/SocketCall";
import ResetPassword from "./App/Screens/MainScreen/ResetPassword/ResetPassword";
import Orders from "./App/Screens/MainScreen/Shop/Orders";
import Inventory from "./App/Screens/MainScreen/Shop/Inventory";
import AddInventory from "./App/Screens/MainScreen/Shop/AddInventory";
import InventoryProductDescription from "./App/Screens/MainScreen/Shop/InventoryProductDescription";
import AddAstrologer from "./App/Screens/MainScreen/Astrologer/AddAstrologer";
import MyAccount from "./App/Screens/MainScreen/MyAccount/MyAccount";
import SendNotification from "./App/Screens/MainScreen/SendNotification/SendNotification";
import Login from "./App/Screens/MainScreen/Auth/Login";
import ForgotPassword from "./App/Screens/MainScreen/Auth/ForgotPassword";
import SetForgotPassword from "./App/Screens/MainScreen/Auth/SetForgotPassword";
import OnBoards from "./App/Screens/MainScreen/Astrologer/SubList/OnBoards";
import Newregistration from "./App/Screens/MainScreen/Astrologer/SubList/Newregistration";
import Approveded from "./App/Screens/MainScreen/Astrologer/SubList/Approveded";
import Rejecteded from "./App/Screens/MainScreen/Astrologer/SubList/Rejecteded";
import Usertransactions from "./App/Screens/MainScreen/Transaction/Transationlist/Usertransactions";
import Astrologertransactions from "./App/Screens/MainScreen/Transaction/Transationlist/Astrologertransactions";
import Earnings from "./App/Screens/MainScreen/Transaction/Transationlist/Earnings";
import { useDispatch, useSelector } from "react-redux";
import { setProfileData } from "./App/Config/Actions/profileActions";
import { ToastContainer, toast } from "react-toastify";
import Banner from "./App/Screens/MainScreen/Banner/AddBanner";
import BannerList from "./App/Screens/MainScreen/Banner/BannerList";
import AddBanner from "./App/Screens/MainScreen/Banner/AddBanner";
import AddAstrologers from "./App/Screens/MainScreen/Astrologer/AddAstrologers";
import GiftSession from "./App/Screens/MainScreen/Sessions/SessionSubList/GiftSession";

const drawerWidth = "15%";

const ListScreenData = [
  {
    text: "Home",
    icon: Icons.ic_grey_home,
    icongWhite: Icons.ic_home,
    route: "/Home",
  },

  {
    text: "Banner",
    icon: Icons.ic_banner_grey,
    icongWhite: Icons.ic_banner_white,
    route: "/BannerList",
    arrowIcon: Icons.ic_down,
    subItems: [
      {
        text: "Banner List",
        route: "/BannerList",
      },
      {
        text: "Add New Banner",
        route: "/AddBanner",
      },
    ],
  },

  {
    text: "Astrologers",
    icon: Icons.ic_astrologers,
    icongWhite: Icons.ic_astrologer_white,
    route: "/Astrologer",
    arrowIcon: Icons.ic_down,
    subItems: [
      {
        text: "Onboarded",
        route: "/Astrologer",
      },
      {
        text: "New registration",
        route: "/Newregistration",
      },
      {
        text: "Approved",
        route: "/Approveded",
      },
      {
        text: "Rejected",
        route: "/Rejecteded",
      },
    ],
  },
  {
    text: "Users",
    icon: Icons.ic_user,
    icongWhite: Icons.ic_user_white,
    route: "/User",
  },
  {
    text: "Transaction",
    icon: Icons.ic_transaction,
    route: "/Usertransactions",
    icongWhite: Icons.ic_transaction_white,
    arrowIcon: Icons.ic_down,
    subItems: [
      {
        text: "User transactions",
        route: "/Usertransactions",
      },
      {
        text: "Astrologer transactions",
        route: "Astrologertransactions",
      },
      {
        text: "Earnings",
        route: "/Earnings",
      },
    ],
  },
  // {
  //   text: "Registration",
  //   icon: Icons.ic_regstration,
  //   icongWhite: Icons.ic_regstration_white,
  //   route: "/Registration",
  // },
  {
    text: "Sessions",
    icon: Icons.ic_sessions,
    icongWhite: Icons.ic_sessions_white,
    route: "/Session",
    arrowIcon: Icons.ic_down,
    subItems: [
      {
        text: "Ongoing ",
        route: "/Ongoing",
      },
      {
        text: "Scheduled ",
        route: "/Scheduled",
      },
      {
        text: "Completed ",
        route: "/Completed",
      },
      {
        text: "Rejected",
        route: "/Rejected",
      },
      {
        text: "Missed",
        route: "/Missed",
      },
      {
        text: "Live",
        route: "/LiveSession",
      },
      {
        text: "Gift",
        route: "/GiftSession",
      },
    ],
  },
  {
    text: "Zigzek shop",
    icon: Icons.ic_shop,
    icongWhite: Icons.ic_shop_white,
    route: "/Shop",
    arrowIcon: Icons.ic_down,
    subItems: [
      {
        text: "Orders",
        route: "/Orders",
      },
      {
        text: "Inventory",
        route: "/Inventory",
      },
    ],
  },
  {
    text: "Coupon codes",
    icon: Icons.ic_coupon,
    icongWhite: Icons.ic_cpupon_white,
    route: "/Coupon",
  },
  {
    text: "Customer support",
    icon: Icons.ic_customer_support,
    icongWhite: Icons.ic_customer_white,
    route: "/Customer",
  },
  {
    text: "Send Notification",
    icon: Icons.ic_notification_grey,
    icongWhite: Icons.ic_white_notification,
    route: "/SendNotification",
  },
];

const App = () => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState("/");
  const [selectedSubItem, setSelectedSubItem] = useState("");
  const [openSubItems, setOpenSubItems] = useState("");
  const [subItemShow, setSubItemShow] = useState(null);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isProfileData = useSelector((state) => state.isProfileData);
  const [isLoggedIn, setIsLoggedIn] = useState(
    isProfileData == null ? false : true
  );

  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    const matchedItem = ListScreenData.find(
      (item) => item.route === currentPath
    );
    if (matchedItem) {
      setSelectedItem(matchedItem.route);
      if (matchedItem.subItems) {
        setSubItemShow(true);
        setOpenSubItems(matchedItem.route);
      } else {
        setSubItemShow(false);
        setOpenSubItems("");
      }
    } else {
      const matchedSubItem = ListScreenData.flatMap(
        (item) => item.subItems || []
      ).find((subItem) => subItem.route === currentPath);
      if (matchedSubItem) {
        setSelectedSubItem(matchedSubItem.route);
        const parentItem = ListScreenData.find(
          (item) => item.subItems && item.subItems.includes(matchedSubItem)
        );
        if (parentItem) {
          setSelectedItem(parentItem.route);
          setOpenSubItems(parentItem.route);
          setSubItemShow(true);
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoggedIn == false) {
      dispatch(setProfileData(null));
      setIsLoggedIn(false);
    }
  }, [isLoggedIn]);

  const handleItemClick = (item) => {
    if (item.text == "Astrologers") {
      setSelectedSubItem("/Astrologer");
      setSubItemShow(selectedItem == item.route ? !subItemShow : true);
      setSelectedItem(item.route || item);
      setOpenSubItems(item.route || item);
    } else if (item.text == "Transaction") {
      setSelectedSubItem("/Usertransactions");
      setSubItemShow(selectedItem == item.route ? !subItemShow : true);
      setSelectedItem(item.route || item);
      setOpenSubItems(item.route || item);
    } else if (item.text == "Banner") {
      setSelectedSubItem("/BannerList");
      setSubItemShow(selectedItem == item.route ? !subItemShow : true);
      setSelectedItem(item.route || item);
      setOpenSubItems(item.route || item);
    } else {
      setSelectedSubItem("");
      setSelectedItem(item.route || item);
      setOpenSubItems(item.route || item);
    }
  };

  const handleSubItemClick = (subItem) => {
    setSelectedSubItem(subItem.route);
  };

  const isSubItemOpen = (route) => {
    return openSubItems === route;
  };
  useEffect(() => {
    const eventListener = (event) => {
      const navLink = event.detail;
      if (navLink == "/Usertransactions") {
        setSelectedSubItem("/Usertransactions");
        setSubItemShow(true);
        setSelectedItem("/Usertransactions");
        setOpenSubItems("/Usertransactions");
      } else {
        setSelectedItem(navLink);
        setSelectedSubItem(event.detail);
      }
    };
    window.addEventListener("tabEventListener", eventListener);
    return () => {
      window.removeEventListener("tabEventListener", eventListener);
    };
  }, []);

  useEffect(() => {
    const eventListener = (event) => {
      const navLink = event.detail;
      setSubItemShow(true);
      setSelectedSubItem(navLink);
    };
    window.addEventListener("subTabEventListner", eventListener);
    return () => {
      window.removeEventListener("subTabEventListner", eventListener);
    };
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      const currentPath = window.location.pathname;
      const currentPathGet = ListScreenData.filter(
        (item) => item.route === currentPath
      );
      if (currentPathGet.length != 0) {
        handleItemClick(currentPathGet[0].route);
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    SocketCall.connect();
  }, []);
  return (
    // <Router>
    <div style={{ backgroundColor: Colors.darkBlack, height: "100vh" }}>
      {isProfileData ? (
        <Box sx={{ display: "flex" }}>
          {isProfileData != null && (
            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  background: Colors.mainDarkColor,
                },
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  padding: 10,
                  maxWidth: "220px", // Set the maximum width
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis", // Add ellipsis for overflowed text
                  gap: 10,
                }}
              >
                <img
                  style={{
                    width: "2.5rem",
                    objectFit: "contain",
                    height: "2.5rem",
                    borderRadius: "2.5rem",
                  }}
                  src={Icons.ic_zigzek_logo}
                  alt="new"
                />
                <div
                  style={{
                    fontFamily: "Poppins-regular",
                    fontSize: "1.2rem",
                    fontWeight: "800",
                    color: Colors.white,
                  }}
                >
                  Zigzek
                </div>
              </div>
              <List
                sx={{
                  maxHeight: "600px",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.5px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#6b6b6b",
                    borderRadius: "8px",
                  },
                }}
              >
                {ListScreenData.map((item, index) => {
                  const isSelected = selectedItem === item.route;
                  const hasSubItems = item.subItems && item.subItems.length > 0;
                  return (
                    <div key={item.text}>
                      <ListItem sx={{}}>
                        <ListItemButton
                          className={`listItemButton
                         ${isSelected ? "active" : ""} no-hover`}
                          sx={{
                            color: "#818181",
                            padding: "0.4rem",
                          }}
                          component={NavLink}
                          to={item.route}
                          onClick={() => handleItemClick(item)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flex: 1,
                              gap: 6,
                            }}
                          >
                            <img
                              style={{
                                width: "1.2rem",
                                objectFit: "contain",
                                height: "1.2rem",
                              }}
                              src={isSelected ? item.icongWhite : item.icon}
                              alt="new"
                            />
                            <div style={{ fontSize: "0.9rem" }}>
                              {item.text}
                            </div>
                            {item.arrowIcon && (
                              <img
                                onClick={() => setSubItemShow(!subItemShow)}
                                style={{
                                  position: "absolute",
                                  right: "1rem",
                                  display: "flex",
                                  width: "1.2rem",
                                  objectFit: "contain",
                                  height: "1.2rem",
                                }}
                                src={item.arrowIcon}
                                alt="new"
                              />
                            )}
                          </div>
                        </ListItemButton>
                      </ListItem>
                      {subItemShow && (
                        <Collapse in={isSubItemOpen(item.route)}>
                          {item?.subItems?.map((subItem, subIndex) => (
                            <ListItem
                              key={subIndex}
                              sx={{
                                display: "flex",
                                paddingLeft: 4,
                                padding: "0.2rem",
                              }}
                            >
                              <ListItemButton
                                className={`listItemButtonactive ${
                                  selectedSubItem == subItem.route
                                    ? "subitem"
                                    : ""
                                }`}
                                sx={{
                                  marginLeft: "1.5rem",
                                  color: "#818181",
                                }}
                                component={NavLink}
                                to={subItem.route}
                                onClick={() => handleSubItemClick(subItem)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flex: 1,
                                    gap: 8,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "0.4rem",
                                      background:
                                        selectedSubItem == subItem.route
                                          ? Colors.white
                                          : "transparent",
                                      height: "0.4rem",
                                      borderRadius: "0.4rem",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      fontFamily: "Poppins-Regular",
                                      fontSize: "0.85rem",
                                    }}
                                  >
                                    {subItem.text}
                                  </div>
                                </div>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </Collapse>
                      )}
                    </div>
                  );
                })}
              </List>
              <Loader />
            </Drawer>
          )}
          <main
            style={{
              backgroundColor: Colors.darkBlack,
              width: `calc(100% - ${drawerWidth})`,
            }}
          >
            <Routes>
              {isProfileData != null && (
                <Route path="/Home" element={<Home />} />
              )}
              <Route path="/AddBanner" element={<AddBanner />} />
              <Route path="/BannerList" element={<BannerList />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route
                path="/SetForgotPassword"
                element={<SetForgotPassword />}
              />
              <Route path="/Astrologer" element={<Astrologer />}></Route>
              <Route path="/User" element={<User />}></Route>
              <Route path="/UserProfile" element={<UserProfile />}></Route>
              <Route
                path="/AstrologerProfile"
                element={<AstrologerProfile />}
              />
              <Route
                path="/SessionStatusDetails"
                element={<SessionStatusDetails />}
              />
              <Route path="/SessionLive" element={<SessionLive />} />
              <Route path="/Session" element={<Session />} />
              <Route path="/Ongoing" element={<Ongoing />} />
              <Route path="/Orders" element={<Orders />} />
              <Route path="/Inventory" element={<Inventory />} />
              <Route path="/Scheduled" element={<Scheduled />} />
              <Route path="/Rejected" element={<Rejected />} />
              <Route path="/Completed" element={<Completed />} />
              <Route path="/Missed" element={<Missed />} />
              <Route path="/LiveSession" element={<LiveSession />} />
              <Route path="/GiftSession" element={<GiftSession />} />
              <Route path="/Coupon" element={<Coupon />} />
              <Route path="/OnBoards" element={<OnBoards />} />
              <Route path="/Customer" element={<Customer />} />
              <Route path="/Newregistration" element={<Newregistration />} />
              <Route path="/Approveded" element={<Approveded />} />
              <Route path="/Rejecteded" element={<Rejecteded />} />
              <Route path="/Usertransactions" element={<Usertransactions />} />
              <Route
                path="/Astrologertransactions"
                element={<Astrologertransactions />}
              />
              <Route path="/Earnings" element={<Earnings />} />

              <Route path="/SendNotification" element={<SendNotification />} />
              <Route path="/Registration" element={<Registration />} />
              <Route path="/Shop" element={<Shop />} />
              <Route path="/QuickSessionInfo" element={<QuickSessionInfo />} />
              <Route path="/AddInventory" element={<AddInventory />} />
              <Route path="/AddAstrologer" element={<AddAstrologer />} />
              <Route path="/AddAstrologers" element={<AddAstrologers />} />
              <Route
                path="/InventoryProductDescription"
                element={<InventoryProductDescription />}
              />
              <Route
                path="/ScheduledSessionInfo"
                element={<ScheduledSessionInfo />}
              />
              <Route path="/Transaction" element={<Transaction />} />
              <Route path="/ResetPassword" element={<ResetPassword />} />
              <Route path="/MyAccount" element={<MyAccount />} />
              <Route
                path="*" // This matches any URL path
                element={<Login onLogin={() => setIsLoggedIn(true)} />}
              />
            </Routes>
          </main>
        </Box>
      ) : (
        <Routes>
          <Route
            path="/"
            element={<Login onLogin={() => setIsLoggedIn(true)} />}
          />
          <Route
            path="*"
            element={
              <Navigate to="/" replace={true} setIsLoggedIn={setIsLoggedIn} />
            }
          />
        </Routes>
      )}

      <ToastContainer />
    </div>
  );
};

export default App;

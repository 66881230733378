import React, { useEffect, useRef, useState } from "react";
import Colors from "../../Constants/Colors";
import "./ScheduledSessionInfo.css";
import "./../../Constants/CommonStyle.css";
import Icons from "../../Constants/Icons";
import Header from "../../Components/Header/Header";
import StatusButton from "../StatusButton/StatusButton";
import Images from "../../Constants/Images";
import { useLocation } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import { apiGetUserQuickSessionInfo } from "../../Core/Apicall";
import { useDispatch } from "react-redux";
import moment from "moment";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent background
  },
  content: {
    padding: 0, // Remove padding
    margin: "auto", // Center horizontally
    maxWidth: "70%", // Optional: set a maximum width for the modal
    width: "70%", // Optional: make the modal responsive
    border: "none", // Remove border (if any)
    background: "none", // Transparent background
    display: "flex", // Use flexbox for vertical centering
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
  },
};

function ScheduledSessionInfo() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { sessionData } = location.state;
  const [sessionDataDetails, setSessionDataDetails] = useState();
  const [lastTimeAudio, setLastItemAudio] = useState(null);
  const [imageSelectedUrl, setImageSelectedUrl] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [currentAudio, setCurrentAudio] = useState(null);
  const [thumbnail, setThumbnail] = useState("");
  const [type, setType] = useState("1");

  const [callChatHistoryType, setCallChatHistoryType] = useState("Video");

  const audioElmRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isVolumeBarVisible, setVolumeBarVisible] = useState(false);

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    audioElmRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  useEffect(() => {
    if (currentAudio) {
      currentAudio.addEventListener("timeupdate", () => {
        setCurrentTime(currentAudio.currentTime);
      });
      if (currentAudio.duration != "NaN") {
        setDuration(currentAudio.duration);
      }

      currentAudio.addEventListener("ended", () => {
        setIsPlaying(false);
        setCurrentTime(0);
        setDuration(0);
      });
      return () => {
        currentAudio.removeEventListener("timeupdate", () => {});
        currentAudio.removeEventListener("ended", () => {});
        currentAudio.removeEventListener("play", () => {});
      };
    }
  }, [currentAudio, isPlaying, currentTime, duration]);

  // useEffect(() => {
  //   if (callChatHistoryType == "Call") {
  //     const audioElement = audioElmRef.current;
  //     audioElement.addEventListener("timeupdate", () => {
  //       setCurrentTime(audioElement.currentTime);
  //     });
  //     audioElement.addEventListener("durationchange", () => {
  //       setDuration(audioElement.duration);
  //     });

  //     audioElement.addEventListener("ended", () => {
  //       setIsPlaying(false);
  //       setCurrentTime(0);
  //     });
  //     return () => {
  //       audioElement.removeEventListener("timeupdate", () => {});
  //       audioElement.removeEventListener("durationchange", () => {});
  //       audioElement.removeEventListener("ended", () => {});
  //     };
  //   }
  // }, []);

  const forwardTenSeconds = () => {
    if (currentAudio) {
      const newTime = Math.min(currentAudio.currentTime + 10, duration);
      currentAudio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const backwardTenSeconds = () => {
    if (currentAudio) {
      const newTime = Math.max(currentAudio.currentTime - 10, 0);
      currentAudio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  }

  useEffect(() => {
    onAPiCallChatHistory();
  }, []);


  const onAPiCallChatHistory = (selectedPage) => {
    let param = {
      userId: sessionData?.userId,
      roomId: sessionData?.roomId,
    };
    dispatch(setLoadingTrue());
    apiGetUserQuickSessionInfo(param)
      .then((response) => {
        setSessionDataDetails(response.data);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const playPauseToggle = (item) => {
    setLastItemAudio(item.audioUrl);
    if (currentAudio) {
      currentAudio.pause();
    }
    if (lastTimeAudio == item.audioUrl) {
      if (isPlaying) {
        if (lastTimeAudio == item.audioUrl) {
          currentAudio.pause();
          setIsPlaying(false);
          return;
        }
      } else {
        if (lastTimeAudio == item.audioUrl) {
          currentAudio.play();
          setIsPlaying(true);
          return;
        }
      }
    } else {
      const audio = new Audio(item.audioUrl);
      setCurrentAudio(audio);
      audio.play();
      setIsPlaying(true);
    }
  };

  const playVideo = (item) => {
    document.body.style.overflow = "hidden";
    setVideoUrl(item.videoUrl);
    setIsModalOpen(true);
  };

  return (
    <div style={{ padding: 20 }}>
      <Header name={"Sessions / SessionID#" + sessionData?.sessionId} />
      <div style={{ display: "flex", flex: 1, gap: "0.5rem" }}>
        <div style={{ maxHeight: "31rem" }} className="myCustomStyle">
          <div className="myCustomStyle2">
            <div style={{}} className="fontSize20">
              {sessionData?.sessionStatus} session info
            </div>
            <div
              style={{
                background:
                  sessionData?.sessionStatus === "completed"
                    ? Colors.mainGreen01
                    : sessionData?.sessionStatus === "cancel" ||
                      sessionData?.sessionStatus == "declined"
                    ? Colors.mainRed01
                    : sessionData?.sessionStatus === "scheduled"
                    ? Colors.mainBlue01
                    : sessionData?.sessionStatus === "missed"
                    ? Colors.lightGrey01
                    : Colors.mainOrange01,
                borderColor:
                  sessionData?.sessionStatus === "completed"
                    ? Colors.darkGreen
                    : sessionData?.sessionStatus === "cancel" ||
                      sessionData?.sessionStatus == "declined"
                    ? Colors.darkRed
                    : sessionData?.sessionStatus === "scheduled"
                    ? Colors.darkBlue
                    : sessionData?.sessionStatus === "missed"
                    ? Colors.darkGrey
                    : Colors.darkOrange,
                color:
                  sessionData?.sessionStatus === "completed"
                    ? Colors.darkGreen
                    : sessionData?.sessionStatus === "cancel" ||
                      sessionData?.sessionStatus == "declined"
                    ? Colors.darkRed
                    : sessionData?.sessionStatus === "scheduled"
                    ? Colors.darkBlue
                    : sessionData?.sessionStatus === "missed"
                    ? Colors.darkGrey
                    : Colors.darkOrange,
              }}
              className="myCustomButton"
            >
              <div style={{ position: "relative", fontWeight: "600" }}>
                {sessionData?.sessionStatus}
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
            }}
          >
            <div className="outer-div">
              <div className="inner-div">
                <div
                  className="fontSize16"
                  style={{
                    fontWeight: 400,
                    color: Colors.greyColor50,
                    position: "relative",
                  }}
                >
                  Session ID
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                  }}
                >
                  <div className="fontSize18">
                    {sessionDataDetails?.sessionid}
                  </div>
                  <img
                    onClick={() =>
                      navigator.clipboard.writeText(
                        sessionDataDetails?.sessionid
                      )
                    }
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      width: "1.2rem",
                      objectFit: "contain ",
                      height: "1.2rem",
                      flexShrink: "0",
                    }}
                    alt=""
                    src={Icons.ic_copy}
                  />
                </div>
              </div>
            </div>
            <div className="borderTopStyle" />
            <div className="mainPersonalInformationContainer">
              <div
                className="fontSize16"
                style={{
                  fontWeight: 400,
                  color: Colors.greyColor50,
                  position: "relative",
                }}
              >
                Date & time
              </div>
              <div className="fontSize18">
                {" "}
                {moment
                  .utc(sessionDataDetails?.dateTime)
                  .local()
                  .format("DD-MM-YYYY hh:mm A")}
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                boxSizing: "border-box",
                height: "0.06rem",
              }}
            />
            <div className="mainPersonalInformationContainer">
              <div
                className="fontSize16"
                style={{
                  fontWeight: 400,
                  color: Colors.greyColor50,
                  position: "relative",
                }}
              >
                Fees
              </div>
              <div className="fontSize18">
                ₹
                {sessionDataDetails?.fees
                  ? sessionDataDetails?.fees + "/min"
                  : "-"}
              </div>
            </div>
            <div className="borderTopStyle" />
            <div className="mainPersonalInformationContainer">
              <div
                className="fontSize16"
                style={{
                  fontWeight: 400,
                  color: Colors.greyColor50,
                  position: "relative",
                }}
              >
                Session type
              </div>
              <div className="fontSize18">
                {sessionDataDetails?.sessiontype}
              </div>
            </div>
            {(sessionData?.sessionStatus != "ongoing" &&
              sessionData?.sessionStatus != "declined") && (
              <div>
                <div className="borderTopStyle" />
                <div className="mainPersonalInformationContainer">
                  <div
                    className="fontSize16"
                    style={{
                      fontWeight: 400,
                      color: Colors.greyColor50,
                      position: "relative",
                    }}
                  >
                    Schedule on
                  </div>
                  <div className="fontSize18">
                    {/* {sessionDataDetails?.sessiontype} */}
                  </div>
                </div>
              </div>
            )}
            {type != 3 && <div className="borderTopStyle" />}
            {type != 3 && (
              <div className="mainPersonalInformationContainer">
                <div
                  className="fontSize16"
                  style={{
                    fontWeight: 400,
                    color: Colors.greyColor50,
                    position: "relative",
                  }}
                >
                  Session duration
                </div>
                <div className="fontSize18">
                  {" "}
                  {sessionDataDetails?.sessionduration}
                </div>
              </div>
            )}
            {type != 3 && <div className="borderTopStyle" />}
            {type != 3 && (
              <div className="mainPersonalInformationContainer">
                <div
                  className="fontSize16"
                  style={{
                    fontWeight: 400,
                    color: Colors.greyColor50,
                    position: "relative",
                  }}
                >
                  Total amount
                </div>

                <div className="fontSize18">
                  {sessionDataDetails?.totalamount
                    ? "₹" + sessionDataDetails?.totalamount
                    : "-"}
                  <span
                    style={{
                      position: "relative",
                      fontSize: "0.7rem",
                      marginLeft: "5px",
                      color: Colors.darkGreen,
                    }}
                    className="fontSize18"
                  >
                    {/* Counting... */}
                    {sessionDataDetails?.isPaid == true ? "Paid" : "Refund"}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{ maxHeight: "35rem", overflow: "auto" }}
          className="chatMainDiv scrollable-container-scrollbar"
        >
          <div style={{ paddingBottom: "1.5rem" }} className="fontSize20">
            {callChatHistoryType == "Call" ? "Call history" : "Chat history"}
          </div>
          {callChatHistoryType == "Call" ? (
            <div style={{}}>
              <div style={{ width: "100%", height: 70 }}>
                <img
                  src={
                    isPlaying ? Images.image_wave_effect : Icons.ic_wave_stop
                  }
                  style={{ height: 70, width: "100%", objectFit: "cover" }}
                />
              </div>
              {/* <audio src={audioUrl ?? ""} ref={audioElmRef} /> */}
              <div
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <img
                  onClick={() => backwardTenSeconds()}
                  src={Icons.ic_backwardseconds}
                  style={{
                    cursor: "pointer",
                    objectFit: "cover",
                    height: "1.5rem",
                    width: "1.5rem",
                  }}
                />
                <img
                  src={isPlaying ? Icons.ic_audio_pause : Icons.ic_play_pause}
                  onClick={() => playPauseToggle()}
                  style={{
                    cursor: "pointer",
                    objectFit: "cover",
                    height: "2rem",
                    width: "2rem",
                  }}
                />
                <img
                  onClick={() => forwardTenSeconds()}
                  src={Icons.ic_forwardseconds}
                  style={{
                    cursor: "pointer",
                    objectFit: "cover",
                    height: "1.5rem",
                    width: "1.5rem",
                  }}
                />
                <img
                  src={Icons.ic_volumehigh}
                  onClick={() => setVolumeBarVisible(!isVolumeBarVisible)}
                  style={{
                    cursor: "pointer",
                    objectFit: "cover",
                    height: "1.5rem",
                    width: "1.5rem",
                  }}
                />
                {isVolumeBarVisible && (
                  <input
                    type="range"
                    min="0"
                    max="1"
                    style={{ filter: "grayscale(100%)" }}
                    step="0.01"
                    value={volume}
                    onChange={(event) => handleVolumeChange(event)}
                  />
                )}
                <div style={{ fontSize: 14 }} className="fontSize20">
                  {" "}
                  {formatTime(currentTime)} / {formatTime(duration)}
                </div>
              </div>
            </div>
          ) : sessionDataDetails?.chatHistory?.length > 0 > 0 ? (
            <div>
              <div>
                {sessionDataDetails?.chatHistory.map((item, index) => (
                  <div key={index} style={{ marginTop: "10px" }}>
                    {item.senderType == "User" ? (
                      <div className="chatParentDiv" key={index}>
                        <div
                          style={{}}
                          className="indian-man-smiling-astrologer"
                        >
                          <img
                            style={{
                              position: "relative",
                              width: "2.63rem",
                              height: "2.63rem",
                            }}
                            alt=""
                            src={item.senderImage ?? Icons.ic_default_user}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-end",
                            gap: "0.13rem",
                          }}
                        >
                          {item.messageType == "text" ? (
                            <div className="chatMsgBorder">
                              <div
                                style={{ fontWeight: 400 }}
                                className="fontSize14"
                              >
                                {item.message}
                              </div>
                            </div>
                          ) : item.messageType == "image" ? (
                            <div
                              onClick={() => {
                                document.body.style.overflow = "hidden";
                                setIsImageModalOpen(true);
                                setImageSelectedUrl(item.imageUrl);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                style={{
                                  objectFit: "cover",
                                  width: "12rem",
                                  borderRadius: "10px",
                                  height: "8rem",
                                }}
                                alt=""
                                src={item.imageUrl}
                              />
                            </div>
                          ) : item.messageType == "video" ? (
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                width: "12rem",
                                height: "8rem",
                              }}
                              onClick={() => playVideo(item)}
                            >
                              <img
                                style={{
                                  position: "absolute",
                                  width: "2rem",
                                  height: "2rem",
                                  alignSelf: "center",
                                  display: "flex",
                                }}
                                alt=""
                                src={Icons.ic_play_pause}
                              />
                              <video
                                src={item.videoUrl}
                                loop
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  borderRadius: "10px",
                                  height: "100%",
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "20rem",
                                height: "auto",
                                border: "0.7px solid grey",
                                borderRadius: "10px",
                                overflow: "hidden",
                                padding: 5,
                              }}
                            >
                              <img
                                src={
                                  isPlaying == true &&
                                  lastTimeAudio == item.audioUrl
                                    ? Images.image_wave_effect
                                    : Icons.ic_wave_stop
                                }
                                style={{
                                  width: "100%",
                                  height: "2rem",
                                  bottom: -5,
                                  objectFit: "cover",
                                }}
                              />
                              <audio
                                src={item.audioUrl ?? ""}
                                ref={audioElmRef}
                                id={`audio-${item.audioUrl}`}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  justifyContent: "center", // Check if overridden elsewhere
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <img
                                    onClick={() => backwardTenSeconds()}
                                    src={Icons.ic_backwardseconds}
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                      height: "1.2rem",
                                      width: "1.2rem",
                                    }}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={
                                      isPlaying == true &&
                                      lastTimeAudio == item.audioUrl
                                        ? Icons.ic_audio_pause
                                        : Icons.ic_play_pause
                                    }
                                    onClick={() => playPauseToggle(item)}
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                      height: "1.2rem",
                                      width: "1.2rem",
                                    }}
                                  />
                                </div>
                                <div>
                                  <img
                                    onClick={() => forwardTenSeconds()}
                                    src={Icons.ic_forwardseconds}
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                      height: "1.2rem",
                                      width: "1.2rem",
                                    }}
                                  />
                                </div>
                                {lastTimeAudio == item.audioUrl ? (
                                  <div
                                    style={{
                                      fontSize: "0.8rem",
                                      color: "#FFF",
                                      fontWeight: "600",
                                      position: "relative",
                                    }}
                                  >
                                    {formatTime(currentTime)} /{" "}
                                    {duration >= 0
                                      ? formatTime(duration)
                                      : "00:00"}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      fontSize: "0.8rem",
                                      color: "#FFF",
                                      fontWeight: "600",
                                      position: "relative",
                                    }}
                                  >
                                    {"00:00"} / {"00:00"}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <div
                            className="fontSize16"
                            style={{
                              fontWeight: 400,
                              color: Colors.greyColor50,
                              position: "relative",
                            }}
                          >
                            {moment
                              .utc(item?.datetime)
                              .local()
                              .format("hh:mm a")}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="chatLastMsgDiv">
                        <div className="chatLastMsgParentDiv">
                          {item.messageType == "text" ? (
                            <div className="chatMsgBorder">
                              <div
                                style={{ fontWeight: 400 }}
                                className="fontSize14"
                              >
                                {item.message}
                              </div>
                            </div>
                          ) : item.messageType == "image" ? (
                            <div
                              onClick={() => {
                                document.body.style.overflow = "hidden";
                                setIsImageModalOpen(true);
                                setImageSelectedUrl(item.imageUrl);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                style={{
                                  objectFit: "cover",
                                  width: "12rem",
                                  borderRadius: "10px",
                                  height: "8rem",
                                }}
                                alt=""
                                src={item.imageUrl}
                              />
                            </div>
                          ) : item.messageType == "video" ? (
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                width: "12rem",
                                height: "8rem",
                              }}
                              onClick={() => playVideo(item)}
                            >
                              <img
                                style={{
                                  position: "absolute",
                                  width: "2rem",
                                  height: "2rem",
                                  alignSelf: "center",
                                  display: "flex",
                                }}
                                alt=""
                                src={Icons.ic_play_pause}
                              />
                              <video
                                src={item.videoUrl}
                                loop
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  borderRadius: "10px",
                                  height: "100%",
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "20rem",
                                height: "auto",
                                border: "0.7px solid grey",
                                borderRadius: "10px",
                                overflow: "hidden",
                                padding: 5,
                              }}
                            >
                              <img
                                src={
                                  isPlaying == true &&
                                  lastTimeAudio == item.audioUrl
                                    ? Images.image_wave_effect
                                    : Icons.ic_wave_stop
                                }
                                style={{
                                  width: "100%",
                                  height: "2rem",
                                  bottom: -5,
                                  objectFit: "cover",
                                }}
                              />
                              <audio
                                src={item.audioUrl ?? ""}
                                ref={audioElmRef}
                                id={`audio-${item.audioUrl}`}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <img
                                    onClick={() => backwardTenSeconds()}
                                    src={Icons.ic_backwardseconds}
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                      height: "1.2rem",
                                      width: "1.2rem",
                                    }}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={
                                      isPlaying == true &&
                                      lastTimeAudio == item.audioUrl
                                        ? Icons.ic_audio_pause
                                        : Icons.ic_play_pause
                                    }
                                    onClick={() => playPauseToggle(item)}
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                      height: "1.2rem",
                                      width: "1.2rem",
                                    }}
                                  />
                                </div>
                                <div>
                                  <img
                                    onClick={() => forwardTenSeconds()}
                                    src={Icons.ic_forwardseconds}
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                      height: "1.2rem",
                                      width: "1.2rem",
                                    }}
                                  />
                                </div>
                                {lastTimeAudio == item.audioUrl ? (
                                  <div
                                    style={{
                                      fontSize: "0.8rem",
                                      color: "#FFF",
                                      fontWeight: "600",
                                      position: "relative",
                                    }}
                                  >
                                    {formatTime(currentTime)} /{" "}
                                    {duration >= 0
                                      ? formatTime(duration)
                                      : "00:00"}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      fontSize: "0.8rem",
                                      color: "#FFF",
                                      fontWeight: "600",
                                      position: "relative",
                                    }}
                                  >
                                    {"00:00"} / {"00:00"}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <div
                            className="fontSize16"
                            style={{
                              fontWeight: 400,
                              color: Colors.greyColor50,
                              position: "relative",
                            }}
                          >
                            {moment
                              .utc(item?.datetime)
                              .local()
                              .format("hh:mm a")}
                          </div>
                        </div>
                        <div
                          style={{}}
                          className="indian-man-smiling-astrologer"
                        >
                          <img
                            style={{
                              position: "relative",
                              width: "2.63rem",
                              height: "2.63rem",
                            }}
                            alt=""
                            src={item?.senderImage ?? Icons.ic_default_user}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                gap: "1.5rem",
                flexDirection: "column",
              }}
            >
              <img
                style={{
                  width: "20rem",
                  objectFit: "fill",
                  flexShrink: "0",
                }}
                alt=""
                src={Images.ic_chat_empty}
              />
              <div
                style={{ fontWeight: "400", color: Colors.greyColor50 }}
                className="fontSize20"
              >
                No data
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        style={{ marginTop: "1rem", display: "flex", flex: 1, gap: "0.5rem" }}
      >
        <div style={{ gap: "0.5rem", border: "0px" }} className="myCustomStyle">
          {/* {type == 3 && ( */}
            <div style={{ padding: "1rem" }} className="myCustomStyle">
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{}} className="fontSize20">
                    Refund
                  </div>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      color: "#2E90FA",
                      fontWeight: 400,
                      display: "flex",
                      fontSize: "0.7rem",
                    }}
                    className="fontSize20"
                  >
                    View transaction
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: "0.7rem" }} className="fontSize20">
                    Refund amount
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-end",

                      fontSize: "0.7rem",
                    }}
                    className="fontSize20"
                  >
                    230
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: "0.7rem" }} className="fontSize20">
                    Status
                  </div>
                  <StatusButton
                    style={{
                      width: "30%",
                    }}
                  >
                    Successful
                  </StatusButton>
                </div>
              </div>
            </div>
          {/* )} */}

          {sessionData?.sessionStatus === "approved" && (
            <div style={{ padding: "1rem" }} className="myCustomStyle">
              <div>
                <div style={{}} className="fontSize20">
                  Refund
                </div>
                <div
                  style={{ marginTop: "1rem", fontSize: "0.7rem" }}
                  className="fontSize20"
                >
                  Refund amount
                </div>
                <div
                  style={{
                    marginTop: "0.2rem",
                    alignItems: "center",
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <input
                    style={{
                      background: "transparent",
                      display: "flex",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      marginTop: "5px",
                      height: "2rem",
                      color: Colors.white,
                      width: "60%",
                      border: "1px solid rgba(255, 255, 255, 0.2)",
                      borderRadius: "30px",
                    }}
                    type="text"
                    placeholder="₹"
                    value={"inputValue"}
                    // onChange={handleInputChange}
                  />
                  <div
                    style={{
                      background: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      height: "2rem",
                      borderRadius: "20px",
                      fontWeight: 400,
                      color: "#1E1E25",
                      fontSize: "0.7rem",
                    }}
                    className="fontSize16"
                  >
                    Send Refund
                  </div>
                </div>
              </div>
            </div>
          )}

          {sessionData?.sessionStatus == "declined"  && (
            <div style={{ flex: 0.4, display: "flex" }}>
              <div
                className="fontSize16"
                style={{
                  fontWeight: 400,

                  color: Colors.greyColor50,
                  position: "relative",
                }}
              >
                Astrologer has canceled the session due to their unavailability
                at the moment.
              </div>
            </div>
          )}

          {sessionData?.sessionStatus === "approved" && (
            <div style={{ flex: 0.4, display: "flex" }}>
              <div
                className="fontSize16"
                style={{
                  fontWeight: 400,
                  color: Colors.greyColor50,
                  position: "relative",
                }}
              >
              Astrologer has reschedule the session due to their unavailability at the moment.
              </div>
            </div>
          )}
          {(sessionData?.sessionStatus === "approved" && (
              <div style={{ gap: "1rem", flex: 1, display: "flex" }}>
                <div style={{}}>
                  <div
                    className="fontSize16"
                    style={{
                      fontWeight: 400,
                      color: Colors.greyColor50,
                      position: "relative",
                    }}
                  >
                    Before
                  </div>
                  <div
                    className="fontSize16"
                    style={{
                      fontWeight: 400,
                      color: Colors.greyColor50,
                      position: "relative",
                    }}
                  >
                    25-07-2023, 03:30 PM
                  </div>
                </div>
                <div style={{}}>
                  <div
                    className="fontSize16"
                    style={{
                      flex: 0.3,
                      fontWeight: 400,

                      color: Colors.greyColor50,
                      position: "relative",
                    }}
                  >
                    After
                  </div>
                  <div
                    className="fontSize16"
                    style={{
                      flex: 0.3,
                      fontWeight: 400,
                      color: Colors.greyColor50,
                      position: "relative",
                    }}
                  >
                    25-07-2023, 03:30 PM
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div style={{ border: "0px" }} className="chatMainDiv"></div>
      </div>

      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => {
          document.body.style.overflow = "unset";
          setVideoUrl();
          setIsModalOpen(false);
        }}
      >
        <div
          style={{
            background: Colors.mainDarkColor,
            padding: 20,
            borderRadius: "10px",
            height: "80%",
            width: "70%",
          }}
        >
          <div style={{ height: "100%", width: "100%", borderRadius: "10px" }}>
            <video
              src={videoUrl}
              autoPlay
              controls
              style={{
                objectFit: "cover",
                width: "100%",
                borderRadius: "10px",
                height: "100%",
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isImageModalOpen}
        onRequestClose={() => {
          document.body.style.overflow = "unset";
          setIsImageModalOpen(false);
          setImageSelectedUrl(null);
        }}
      >
        <div
          style={{
            background: Colors.mainDarkColor,
            padding: 20,
            borderRadius: "10px",
            height: "80%",
            width: "70%",
          }}
        >
          <div style={{ height: "100%", width: "100%", borderRadius: "10px" }}>
            <img
              style={{
                objectFit: "cover",
                width: "100%",
                borderRadius: "10px",
                height: "100%",
              }}
              alt=""
              src={imageSelectedUrl}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ScheduledSessionInfo;

import React, { useState } from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import StatusButton from "../StatusButton/StatusButton";
import moment from "moment";

function CustomOrderListRenderData({
  productInformationShow,
  data,
  orderStatusChange,
  hideMore,
}) {
  const onClickIdCopy = (item) => {
    navigator.clipboard.writeText(item);
  };
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const toggleMenu = (index) => {
    if (index === openMenuIndex) {
      setOpenMenuIndex(-1);
    } else {
      setOpenMenuIndex(index);
    }
  };

  const onClickStatusChange = (status, item) => {
    orderStatusChange(status, item);
    closeMenu();
  };

  const closeMenu = () => {
    setOpenMenuIndex(-1);
  };
  return (
    <div>
      {data.map((item, index) => {
        return (
          <div
          key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              padding: "0.8rem",
              alignSelf: "stretch",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: productInformationShow ? "15%" : "20%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.5rem",
                alignSelf: "stretch",
              }}
            >
              <div
                style={{
                  // flexWrap: "wrap",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                  // whiteSpace: "nowrap",
                  wordBreak: "break-word",width:'70%',
                }}
                className="fontSize18Weight400"
              >
                {item?.orderShortId ?? item?.orderId}
              </div>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => onClickIdCopy(item?.orderShortId ?? item?.orderId)}
                className="icon_copy"
                alt=""
                src={Icons.ic_copy}
              />
            </div>
            <div
              style={{
                width: productInformationShow ? "22%" : "35%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div style={{}} className="indian-man-smiling-astrologer">
                <img
                  style={{
                    position: "relative",
                    width: "2.63rem",
                    height: "2.63rem",
                  }}
                  alt=""
                  src={item?.userImage ?? Icons.ic_default_user}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div className="fontSize18Weight400">{item?.userName}</div>
                <div
                  style={{
                    color: Colors.greyColor50,
                    wordBreak: "break-word",
                  }}
                  className="fontSize18Weight400"
                >
                  {item?.userId}
                </div>
              </div>
            </div>
            {productInformationShow && (
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                }}
              >
                <div style={{}} className="indian-man-smiling-astrologer">
                  <img
                    style={{
                      position: "relative",
                      width: "2.63rem",
                      height: "2.63rem",
                    }}
                    alt=""
                    src={item.products[0]?.productImage ?? Icons.ic_default_user}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div className="fontSize18Weight400">
                    {item?.products[0]?.productName}
                  </div>
                  <div
                    style={{
                      color: Colors.greyColor50,
                    }}
                    className="fontSize18Weight400"
                  >
                    {item?.products[0]?.productPrice}
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                width: productInformationShow ? "15%" : "22%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="fontSize18Weight400">
                {moment(item?.order_date_and_time).format("DD-MM-YYYY")} <br />
                {moment(item?.order_date_and_time).format("hh:mm a")}
              </div>
            </div>
            <div
              className="fontSize18Weight400"
              style={{
                width: "10%",
              }}
            >
              {item?.amount == undefined ? "₹0" : "₹" + item?.amount}
            </div>
            <StatusButton
              style={{
                width: "10%",
                backgroundColor:
                  item?.orderStatus === "New"
                    ? Colors.mainBlue01
                    : item?.orderStatus === "Canceled"
                    ? Colors.mainRed01
                    : Colors.mainOrange01,
                borderColor:
                  item?.orderStatus === "New"
                    ? Colors.darkBlue
                    : item?.orderStatus === "Canceled"
                    ? Colors.darkRed
                    : Colors.darkOrange,
                color:
                  item?.orderStatus === "New"
                    ? Colors.darkBlue
                    : item?.orderStatus === "Canceled"
                    ? Colors.darkRed
                    : Colors.darkOrange,
              }}
            >
              {item?.orderStatus === "Canceled" ? "Cancelled" : item?.orderStatus}
            </StatusButton>
            <div
              onClick={() => toggleMenu(index)}
              style={{
                visibility: hideMore && "hidden",
                cursor: "pointer",
                width: "3%",
                display: "flex",
              }}
            >
              {(item?.orderStatus != "Canceled" &&  item?.orderStatus != "Delivered") && (
                <img
                  style={{ width: "1.2rem", objectFit: "cover" }}
                  src={Icons.ic_more}
                />
              )}
            </div>
            <div
              style={{
                marginBottom: index === data.length - 1 ? "80px" : "auto",
                right: "1rem",
                top: index == 0 ? "13px" : "auto",
              }}
              className="custom-menu-popup-container"
            >
              {openMenuIndex === index && (
                <div
                  style={{ width: "auto" }}
                  onMouseLeave={closeMenu}
                  className="custom-menu-popup"
                >
                  {item?.orderStatus != "Dispatched" && (
                    <div
                      onClick={() => onClickStatusChange("Dispatched", item)}
                      className="custom-menu-list"
                    >
                      Dispatched
                    </div>
                  )}
                 {item?.orderStatus != "New" &&  <div
                    onClick={() => onClickStatusChange("Delivered", item)}
                    className="custom-menu-list"
                  >
                    Delivered
                  </div>
                 }
                  <div
                    onClick={() => onClickStatusChange("Canceled", item)}
                    className="custom-menu-list"
                  >
                    Cancelled
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CustomOrderListRenderData;

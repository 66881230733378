import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./HomeStyle.css";
import Icons from "../../../Constants/Icons";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import TransactionList from "../../../Components/CustomHomeScren/TransactionList";
import SessionCustom from "../../../Components/CustomHomeScren/SessionCustom";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Header from "../../../Components/Header/Header";
import Loader from "../../../Components/Loader/Loader";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import { useNavigate } from "react-router-dom";
import {
  api_Get_Session_List,
  apiCallHomeTransactionGetRevenue,
  apiCallHomeTransactionGetTaxAmount,
  get_api_astrologer_getAstrologerLiveUpdates,
  post_userTransactionHistory,
} from "../../../Core/Apicall";
import moment from "moment";

const optionsChart = {
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
};

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const revenueTaxActiveDropdownList = [
    "Today",
    "This Week",
    "Last Week",
    "This Month",
    "All Time",
  ];
  const [showAllTransactionData, setShowAllTransactionData] = useState(false);
  const [showAllSessionListData, setShowAllSessionListData] = useState(false);
  const [transactionsList, setTransactionList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [astrologerLiveUpdateTime, setAstrologerLiveUpdateTime] = useState();
  const [transactionLiveUpdateTime, setTransactionLiveUpdateTime] = useState();
  const [sessionLiveUpdateTime, setSessionLiveUpdateTime] = useState();

  const [totalTransactionNumber, setTotalTransactionNumber] = useState(0);
  const [totalTransactionSuccess, setTotalTransactionSuccess] = useState(0);
  const [totalTransactionFailed, setTotalTransactionFailed] = useState(0);

  const [astrologerLiveUpdate, setAstrologerLiveDetails] = useState();
  const [revenueChartData, setRevenueChartData] = useState();
  const [revenueChartTotalNumber, setRevenueChartTotalNumber] = useState(0);

  const [taxChartData, setTaxChartData] = useState();
  const [taxChartTotalNumber, setTaxChartTotalNumber] = useState(0);

  useEffect(() => {
    onApiCallAstrologerLiveUpdates();
    onApiCallTransactionListApi();
    onApiCallGetSessionList();
    onApiCallRevenueChart("Today");
    onApiCallTaxChart("Today");
  }, []);

  const onApiCallTaxChart = async (type) => {
    dispatch(setLoadingTrue());
    try {
      const param = {
        filterType:
          type === "Today"
            ? "today"
            : type === "This Week"
            ? "thisWeek"
            : type === "Last Week"
            ? "lastWeek"
            : type === "This Month"
            ? "thisMonth"
            : "allTime",
      };
      const res = await apiCallHomeTransactionGetTaxAmount(param);
      let fillUpData = {
        labels: ["1", "2"],
        datasets: [
          {
            responsive: true,
            data: [0, res?.totalTaxAmount],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 250);
              gradient.addColorStop(0, Colors.mainOrange40);
              gradient.addColorStop(0.26, Colors.mainOrange0);
              return gradient;
            },
            borderColor: Colors.mainOrange,
            borderWidth: 1.5,
            lineTension: 0.4,
            fill: true,
          },
        ],
      };
      setTaxChartData(fillUpData);
      setTaxChartTotalNumber(res?.totalTaxAmount);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };
  const onApiCallRevenueChart = async (type) => {
    dispatch(setLoadingTrue());
    try {
      const param = {
        filterType:
          type === "Today"
            ? "today"
            : type === "This Week"
            ? "thisWeek"
            : type === "Last Week"
            ? "lastWeek"
            : type === "This Month"
            ? "thisMonth"
            : "allTime",
      };
      const res = await apiCallHomeTransactionGetRevenue(param);
      let fillUpData = {
        labels: ["1", "2"],
        datasets: [
          {
            responsive: true,
            data: [0, res?.totalRevenue],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 250);
              gradient.addColorStop(0, Colors.mainGreen40);
              gradient.addColorStop(0.26, Colors.mainGreen0);
              return gradient;
            },
            borderColor: Colors.mainGreen,
            borderWidth: 1.5,
            lineTension: 0.4,
            fill: true,
          },
        ],
      };
      setRevenueChartData(fillUpData);
      setRevenueChartTotalNumber(res?.totalRevenue);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const onApiCallGetSessionList = (selectedPage) => {
    let param = {
      offset: 10 * (selectedPage?.selected || 0),
      limit: 10,
    };

    let data = {
      type: "all",
    };
    dispatch(setLoadingTrue());
    api_Get_Session_List(param, data)
      .then((response) => {
        setSessionLiveUpdateTime(moment().format("DD MMM,YY hh:mm A"));
        setSessionList(response?.sessionlist);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onApiCallTransactionListApi = (selectedPage) => {
    const param = {
      offset: 10 * (selectedPage?.selected || 0),
      limit: 10,
    };

    dispatch(setLoadingTrue());
    post_userTransactionHistory(param)
      .then((response) => {
        setTotalTransactionNumber(response?.totalTransactionsCount);
        setTotalTransactionSuccess(response?.totalSuccessTransactionCount);
        setTotalTransactionFailed(response?.totalFailedTransactionCount);

        setTransactionLiveUpdateTime(moment().format("DD MMM,YY hh:mm A"));
        setTransactionList(response.data);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onApiCallAstrologerLiveUpdates = async () => {
    dispatch(setLoadingTrue());
    get_api_astrologer_getAstrologerLiveUpdates()
      .then((response) => {
        setAstrologerLiveUpdateTime(moment().format("DD MMM,YY hh:mm A"));
        setAstrologerLiveDetails(response.data);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {});
  };


  const onClickShowAstrologerNav = (navLink) => {
    const event = new CustomEvent("tabEventListener", { detail: navLink });
    window.dispatchEvent(event);
    navigate(navLink);
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Home"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="revenueTaxActiveMainDiv">
          <div
            style={{
              flex: "1",
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              padding: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",

                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div className="revenueMainDiv">Revenue</div>
                <div className="revenueTaxActiveMainDivDropdown">
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={revenueTaxActiveDropdownList}
                    onSelect={(item) => onApiCallRevenueChart(item)}
                  />
                </div>
              </div>
              <div className="revenueValueDiv">₹ {revenueChartTotalNumber}</div>
              {revenueChartData && (
                <Line
                  height={50}
                  style={{ width: "100%" }}
                  data={revenueChartData}
                  options={optionsChart}
                />
              )}
            </div>
          </div>

          <div
            style={{
              flex: "1",
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              padding: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div className="revenueMainDiv">Tax amount</div>
                <div className="revenueTaxActiveMainDivDropdown">
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={revenueTaxActiveDropdownList}
                    onSelect={(item) => onApiCallTaxChart(item)}
                  />
                </div>
              </div>
              <div className="revenueValueDiv">₹ {taxChartTotalNumber}</div>
              {taxChartData && (
                <Line
                  height={50}
                  style={{ width: "100%" }}
                  data={taxChartData}
                  options={optionsChart}
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              gap: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                }}
              >
                <div className="astrologerText">Astrologer live updates</div>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => onApiCallAstrologerLiveUpdates()}
                  className="showMoreIcon"
                  alt=""
                  src={Icons.ic_refresh}
                />
                <div className="dateTimeGreyColorShow">
                  Updated on {astrologerLiveUpdateTime}
                </div>
              </div>
              <div
                // onClick={() => navigate("/Astrologer")}
                onClick={() => onClickShowAstrologerNav("/Astrologer")}
                className="showMoreMainDiv"
              >
                <div className="dateTimeGreyColorShow">Show more</div>
                <img
                  className="showMoreIcon"
                  alt=""
                  src={Icons.ic_arrow_right}
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1rem",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  flex: "1",
                  borderRadius: "8px",
                  backgroundColor: Colors.borderColor,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "0.5rem 1rem",
                  gap: "1rem",
                }}
              >
                <div
                  className="lastWeekEndDiv"
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontWeight: "500",
                  }}
                >
                  New joined
                </div>
                <div
                  className="astrologerTextValueShow"
                  style={{
                    color: Colors.darkBlue,
                  }}
                >
                  {astrologerLiveUpdate?.newJoined}
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  borderRadius: "8px",
                  backgroundColor: Colors.borderColor,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "0.5rem 1rem",
                  gap: "1rem",
                }}
              >
                <div
                  className="lastWeekEndDiv"
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontWeight: "500",
                  }}
                >
                  Online astrologers
                </div>
                <div
                  className="astrologerTextValueShow"
                  style={{
                    color: Colors.darkGreen,
                  }}
                >
                  {astrologerLiveUpdate?.onlineAstrologers}
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  borderRadius: "8px",
                  backgroundColor: Colors.borderColor,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "0.5rem 1rem",
                  gap: "1rem",
                }}
              >
                <div
                  className="lastWeekEndDiv"
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontWeight: "500",
                  }}
                >
                  Busy astrologers
                </div>
                <div
                  className="astrologerTextValueShow"
                  style={{
                    color: Colors.darkOrange,
                  }}
                >
                  {astrologerLiveUpdate?.busyAstrologers}
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  borderRadius: "8px",
                  backgroundColor: Colors.borderColor,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "0.5rem 1rem",
                  gap: "1rem",
                }}
              >
                <div
                  className="lastWeekEndDiv"
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontWeight: "500",
                  }}
                >
                  Offline astrologer
                </div>
                <div
                  className="astrologerTextValueShow"
                  style={{
                    color: Colors.darkRed,
                  }}
                >
                  {astrologerLiveUpdate?.offlineAstrologers}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="revenueTaxActiveMainDiv">
          <div
            style={{
              width: "50%",
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "1rem 1rem 0rem",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  <div style={{}} className="astrologerText">
                    Transactions
                  </div>
                  <img
                    className="showMoreIcon"
                    onClick={() => onApiCallTransactionListApi()}
                    style={{
                      cursor: "pointer",
                      overflow: "hidden",
                      flexShrink: "0",
                    }}
                    alt=""
                    src={Icons.ic_refresh}
                  />
                  <div className="dateTimeGreyColorShow">
                    Updated on {transactionLiveUpdateTime}
                  </div>
                </div>
                <div
                  // onClick={
                  //   () => navigate("/Transaction")
                  //   // setShowAllTransactionData(!showAllTransactionData)
                  // }
                  onClick={() => onClickShowAstrologerNav("/Usertransactions")}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "right",
                    fontSize: "1rem",
                    cursor: "pointer",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <div className="dateTimeGreyColorShow">Show more</div>
                  <img
                    className="showMoreIcon"
                    alt=""
                    src={Icons.ic_arrow_right}
                  />
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      borderRadius: "8px",
                      backgroundColor: Colors.borderColor,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0.5rem 1rem",
                    }}
                  >
                    <div className="lastWeekEndDiv">Total</div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "1.25rem",
                        fontWeight: "500",
                        color: Colors.darkBlue,
                      }}
                    >
                      {totalTransactionNumber}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "1",
                      borderRadius: "8px",
                      backgroundColor: Colors.borderColor,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0.5rem 1rem",
                    }}
                  >
                    <div className="lastWeekEndDiv">Successful</div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "1.25rem",
                        fontWeight: "500",
                        color: Colors.darkGreen,
                      }}
                    >
                      {totalTransactionSuccess}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "1",
                      borderRadius: "8px",
                      backgroundColor: Colors.borderColor,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0.5rem 1rem",
                    }}
                  >
                    <div className="lastWeekEndDiv">Failed</div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "1.25rem",
                        fontWeight: "500",
                        color: Colors.darkRed,
                      }}
                    >
                      {totalTransactionFailed}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="scrollable-container"
              style={{
                maxHeight: showAllTransactionData ? "none" : "230px",
              }}
            >
              {transactionsList.map((transaction, index) => (
                <TransactionList
                  key={index}
                  data={transaction}
                  isLast={index === transactionsList.length - 1}
                />
              ))}
            </div>
          </div>
          <div
            style={{
              width: "50%",
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              gap: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                }}
              >
                <div className="astrologerText">Sessions</div>
                <img
                  onClick={() => onApiCallGetSessionList()}
                  style={{ cursor: "pointer" }}
                  className="showMoreIcon"
                  alt=""
                  src={Icons.ic_refresh}
                />

                <div className="dateTimeGreyColorShow">
                  Updated on {sessionLiveUpdateTime}
                </div>
              </div>
              <div
                className="showMoreMainDiv"
                // onClick={() =>
                //   setShowAllSessionListData(!showAllSessionListData)
                // }
                // onClick={
                //   () => navigate("/Session")
                //   // setShowAllTransactionData(!showAllTransactionData)
                // }
                onClick={() => onClickShowAstrologerNav("/Session")}
              >
                <div className="dateTimeGreyColorShow">Show more</div>
                <img
                  className="showMoreIcon"
                  alt=""
                  src={Icons.ic_arrow_right}
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  backgroundColor: Colors.borderColor,
                  borderBottom: Colors.darkBorderColor,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 0.5)",
                  justifyContent: "space-between",
                  padding: "1rem",
                }}
              >
                <div
                  className="lastWeekEndDivGreyColor"
                  style={{
                    width: "28%",
                  }}
                >
                  ID
                </div>
                <div
                  className="lastWeekEndDivGreyColor"
                  style={{
                    width: "22%",
                  }}
                >
                  Session type
                </div>
                <div
                  className="lastWeekEndDivGreyColor"
                  style={{
                    width: "13%",
                  }}
                >
                  Fees
                </div>
                <div
                  className="lastWeekEndDivGreyColor"
                  style={{
                    width: "15%",
                  }}
                >
                  Duration
                </div>
                <div
                  style={{
                    width: "20%",
                  }}
                  className="lastWeekEndDivGreyColor"
                >
                  Status
                </div>
              </div>
              <div
                style={{
                  maxHeight: showAllSessionListData ? "none" : "230px",
                }}
                className="scrollable-container"
              >
                {sessionList.map((session, index) => (
                  <SessionCustom
                    key={index}
                    data={session}
                    // transactionId={session.transactionId}
                    // amount={session.amount}
                    // status={session.status}
                    // sessionType={session.sessionType}
                    // duration={session.duration}
                    isLast={index === sessionList.length - 1}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import Header from "../../../Components/Header/Header";
import Icons from "../../../Constants/Icons";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAstrologerApiCall,
  apiCallUserLanguage,
  apiCallUsersCheckUserExistsMobileNumber,
  getExpertises,
  postEditAstrologerDetails,
} from "../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import { CustomUploadImage } from "../../../Core/CustomUploadImage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import useDownloader from "react-use-downloader";

function AddAstrologer() {
  const { download } = useDownloader();
  const location = useLocation();
  const isProfile = useSelector((state) => state.isProfileData);
  const { data, dataEdit } = location.state || {};
  const fileProductInputRef = useRef(null);
  const fileAadharFrontInputRef = useRef(null);
  const fileAadharBackInputRef = useRef(null);
  const fileAadharPancardInputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userExpertiesRole, setUserExpertiesRole] = useState([]);
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    displayname: false,
    email: false,
    mobileCode: false,
    userRole: false,
    mobileNumber: false,
    productImage: false,
    gender: false,
    experience: false,
    expertise: false,
    bio: false,
    aadharNumber: false,
    aadharImageFrontSide: false,
    aadharImageBackSide: false,
    pancardNumber: false,
    panCardImage: false,
    accountHolderName: false,
    bankAccountNumber: false,
    ifscCodeNumber: false,
    bankName: false,
    priceperminut: false,
    astrologercharge: false,
    zigzekmargin: false,
    marginpercentage: false,
    discountprice: false,
    discount_percentage: false,
  });

  const [emailExisting, setEmailExisting] = useState(false);
  const [mobileExisting, setMobileExisting] = useState(false);

  const [formData, setFormData] = useState({
    Languages: data?.Languages ?? [],
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    displayname: data?.displayname ?? "",
    email: data?.email ?? "",
    mobileCode: data?.mobileCode ?? "+91",
    mobileNumber: JSON.stringify(data?.mobileNumber) ?? "",
    productImage: data?.productImage ?? "",
    gender: data?.gender ?? "",
    experience: data?.experience ?? "",
    expertise: data?.expertise ?? [],
    userRole: data?.userRole ?? "",
    bio: data?.bio ?? "",
    aadharNumber: JSON.stringify(data?.aadharNumber) ?? "",
    aadharImageFrontSide: data?.aadharImageFrontSide ?? "",
    aadharImageBackSide: data?.aadharImageBackSide ?? "",
    pancardNumber: data?.pancardNumber ?? "",
    panCardImage: data?.panCardImage ?? "",
    accountHolderName: data?.accountHolderName ?? "",
    bankAccountNumber: data?.bankAccountNumber ?? "",
    ifscCodeNumber: data?.ifscCodeNumber ?? "",
    bankName: data?.bankName ?? "",
    priceperminut: data?.priceperminut ?? "",
    astrologercharge: data?.astrologercharge ?? "",
    zigzekmargin: data?.zigzekmargin ?? "",
    marginpercentage: data?.marginpercentage ?? "",
    discountprice: data?.discountprice ?? "",
    discount_percentage: data?.discount_percentage ?? "",
  });

  useEffect(() => {
    onApiCallGetExpiertes();
    onApiCallLanguageList();
  }, []);

  const onApiCallExistingMobileNumber = async (item) => {
    let param = {
      Email: formData.email,
      Mobile: formData.mobileNumber,
      countryCode: formData.mobileCode,
      checkType: item,
    };

    try {
      const response = await apiCallUsersCheckUserExistsMobileNumber(param);
      if (item == "Email") {
        setEmailExisting(
          data?.email == formData.email ? false : response?.isEmailExists
        );
      } else {
        setMobileExisting(
          data?.mobileNumber == formData.mobileNumber
            ? false
            : response?.isMobileExists
        );
      }
    } catch (err) {
    } finally {
      dispatch(setLoadingFalse());
    }
  };

  const onApiCallLanguageList = () => {
    dispatch(setLoadingTrue());
    apiCallUserLanguage()
      .then((response) => {
        const updatedList = response?.Languages.map((item1) => ({
          ...item1,
          selected: data?.Languages.some((item) => item1._id === item.id),
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          Languages: updatedList,
        }));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };
  const onApiCallGetExpiertes = () => {
    dispatch(setLoadingTrue());
    getExpertises()
      .then((response) => {
        setUserExpertiesRole(
          response.Expertises.map((expertise) => expertise?.ExpertiseName)
        );
        const updatedList = response?.Expertises.map((expertise) => ({
          ...expertise,
          selected: data?.expertise.some(
            (item) => item.expertiseId === expertise._id
          ),
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          expertise: updatedList,
        }));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleImageChangeProduct = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          productImage: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
    const formData = new FormData();
    formData.append("astrologerImages", event.target.files[0]);
    formData.append("astrologerId", 123456);
    formData.append("imageType", "uploadAstrologerProfile");
    try {
      const data = await CustomUploadImage(formData, isProfile.token);
      if (data && data.status === true) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          productImage: data.location,
        }));
      } else {
        // Handle errors if needed
      }
    } catch (error) {}
  };

  const handleLanguageSelected = (index) => {
    const updatedData = formData?.Languages.map((item, i) => {
      if (i === index) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      Languages: updatedData,
    }));
  };

  const handleItemClick = (index) => {
    const updatedServiceData = formData.expertise.map((item, i) => {
      if (i === index) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      expertise: updatedServiceData,
    }));
  };

  const handleImageChangeAadharFront = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          aadharImageFrontSide: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
    const formData = new FormData();
    formData.append("astrologerImages", event.target.files[0]);
    formData.append("astrologerId", 123456);
    formData.append("imageType", "adharCard");
    try {
      const data = await CustomUploadImage(formData, isProfile.token);
      if (data && data.status === true) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          aadharImageFrontSide: data.location,
        }));
      } else {
        // Handle errors if needed
      }
    } catch (error) {}
  };

  const handleImageChangeAadharBack = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          aadharImageBackSide: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
    const formData = new FormData();
    formData.append("astrologerImages", event.target.files[0]);
    formData.append("astrologerId", 123456);
    formData.append("imageType", "adharCard");
    try {
      const data = await CustomUploadImage(formData, isProfile.token);
      if (data && data.status === true) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          aadharImageBackSide: data.location,
        }));
      } else {
        // Handle errors if needed
      }
    } catch (error) {}
  };

  const handleImageChangePancard = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          panCardImage: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
    const formData = new FormData();
    formData.append("astrologerImages", event.target.files[0]);
    formData.append("astrologerId", 123456);
    formData.append("imageType", "panCard");
    try {
      const data = await CustomUploadImage(formData, isProfile.token);
      if (data && data.status === true) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          panCardImage: data.location,
        }));
      } else {
        // Handle errors if needed
      }
    } catch (error) {}
  };

  const handleInputChange = (e, fieldName) => {
    let value = e.target.value;

    if (
      fieldName == "priceperminut" ||
      fieldName == "discount_percentage" ||
      fieldName == "discountprice" ||
      fieldName == "marginpercentage" ||
      fieldName == "astrologercharge" ||
      fieldName == "zigzekmargin"
    ) {
      value = value.replace(/[^0-9]/g, "");

      if (fieldName == "discountprice") {
        if (parseInt(value, 10) > parseInt(formData.priceperminut, 10)) {
          alert("Discount price cannot be greater than price per minute");
          return;
        }
      } else if (fieldName == "priceperminut") {
        if (parseInt(value, 10) < parseInt(formData.discountprice, 10)) {
          setFormData({
            ...formData,
            [fieldName]: value,
            discountprice: "",
            zigzekmargin: "",
          });
          return;
        }
      } else if (fieldName == "zigzekmargin") {
        let discountPriceValue = parseInt(formData.discountprice || "0", 10);
        let zigzekMarginValue = parseInt(value || "0", 10);
        let additionValue = discountPriceValue + zigzekMarginValue;
        if (
          parseInt(value || "0", 10) >
          parseInt(formData.discountprice || "0", 10)
        ) {
          if (additionValue > 100) {
            alert(
              "The sum of discount price and zigzek margin cannot exceed 100"
            );
            return;
          }
          return;
        }
      }
    }

    if (
      fieldName === "mobileNumber" ||
      fieldName === "aadharNumber" ||
      fieldName == "experience" ||
      fieldName == "priceperminut" ||
      fieldName == "discount_percentage" ||
      fieldName == "discountprice" ||
      fieldName == "marginpercentage" ||
      fieldName == "astrologercharge" ||
      fieldName == "zigzekmargin" ||
      fieldName == "bankAccountNumber"
    ) {
      if (!/^\d*$/.test(value)) {
        return; // Ignore non-numeric input
      }
    }

    if (fieldName == "discountprice") {
      let data = (value * 100) / formData.priceperminut;
      let marginPer = (formData.zigzekmargin * 100) / value;

      let zigzekMarginn =
        parseInt(value || "0", 10) > parseInt(formData.zigzekmargin || "0", 10)
          ? parseInt(value || "0", 10) -
            parseInt(formData.zigzekmargin || "0", 10)
          : parseInt(formData.zigzekmargin || "0", 10) -
            parseInt(value || "0", 10);
      if (!isFinite(marginPer)) {
        marginPer = 0;
      }
      if (!isFinite(zigzekMarginn)) {
        zigzekMarginn = 0;
      }

      let finalDis = 100 - data.toFixed(2);

      if (!isFinite(finalDis)) {
        finalDis = 0;
      }
      setFormData({
        ...formData,
        [fieldName]: value,
        discount_percentage: finalDis.toFixed(2),
        marginpercentage: marginPer.toFixed(2),
        astrologercharge: zigzekMarginn.toFixed(2),
        zigzekmargin: "",
      });
    } else if (fieldName == "priceperminut") {
      let data = (formData.discountprice * 100) / value;

      let astroCharge = value - formData.zigzekmargin;
      let marchingPer = (formData.zigzekmargin * 100) / value;
      let disFinal = 100 - data;
      if (!isFinite(astroCharge)) {
        astroCharge = 0;
      }
      if (!isFinite(marchingPer)) {
        marchingPer = 0;
      }
      if (!isFinite(disFinal)) {
        disFinal = 0;
      }
      setFormData({
        ...formData,
        [fieldName]: value,
        discount_percentage: disFinal.toFixed(2),
        marginpercentage: marchingPer.toFixed(2),
        astrologercharge: astroCharge.toFixed(2),
      });
    } else if (fieldName == "zigzekmargin") {
      let data = formData.discountprice - value;
      let data2 = (value * 100) / formData.discountprice;
      if (!isFinite(data2)) {
        data2 = 0;
      }
      if (!isFinite(data)) {
        data = 0;
      }
      setFormData({
        ...formData,
        [fieldName]: value,
        marginpercentage: data2.toFixed(2),
        astrologercharge: data.toFixed(2),
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
  };

  const validateForm = () => {
    const errors = {
      firstName: formData.firstName == "",
      email: !/\S+@\S+\.\S+/.test(formData.email),
      lastName: formData.lastName == "",
      displayname: formData.displayname == "",
      mobileNumber: formData.mobileNumber.length != 10,
      productImage: formData.productImage == "",
      gender: formData.gender == "",
      experience: formData.experience == "",
      bio: formData.bio == "",
      aadharNumber: formData.aadharNumber.length != 12,
      aadharImageFrontSide: formData.aadharImageFrontSide == "",
      aadharImageBackSide: formData.aadharImageBackSide == "",
      pancardNumber: formData.pancardNumber.length != 10,
      panCardImage: formData.panCardImage == "",
      accountHolderName: formData.accountHolderName == "",
      bankAccountNumber: formData.bankAccountNumber == "",
      ifscCodeNumber: formData.ifscCodeNumber == "",
      bankName: formData.bankName == "",
      priceperminut: formData.priceperminut == "",
      astrologercharge: formData.astrologercharge == "",
      zigzekmargin: formData.zigzekmargin == "",
      marginpercentage: formData.marginpercentage == "",
      discountprice: formData.discountprice == "",
      discount_percentage: formData.discount_percentage == "",
      userRole: formData.userRole == "",
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const onClickDiscardAstrologer = () => {
    // navigate("/Newregistration")
    // const event = new CustomEvent("subTabEventListner", {
    //   detail: "/Newregistration",
    // });
    // window.dispatchEvent(event);
    // navigate("/Newregistration");
    navigate(-1);
    // dispatch(setLoadingFalse());
  };

  const onClickSave = () => {
    if (validateForm()) {
      if (!emailExisting && !mobileExisting) {
        let selectedLanguages = formData?.Languages.filter(
          (item) => item.selected
        );
        let newArrayLanguage = selectedLanguages.map((item) => {
          return {
            id: item._id,
            language: item.LanguageName,
          };
        });
        if (newArrayLanguage.length == 0) {
          alert("Please Select Language");
          return;
        }

        let selectedExperties = formData.expertise.filter(
          (item) => item.selected
        );
        let newArray = selectedExperties.map((item) => {
          return {
            expertiseId: item._id,
            expertise: item.ExpertiseName,
          };
        });

        if (newArray.length == 0) {
          alert("Please Select expertise's");
          return;
        }
        let param = {
          firstname: formData.firstName,
          lastname: formData.lastName,
          Email: formData.email,
          Mobile: formData.mobileNumber,
          profileUrl: formData.productImage,
          gender: formData.gender,
          experience: formData.experience,
          expertise: newArray,
          Bio: formData.bio,
          countryCode: formData.mobileCode,
          Languages: newArrayLanguage,
          price_per_minute: formData.priceperminut,
          display_name: formData.displayname,
          astrologer_charge: formData.astrologercharge,
          zigzek_margin: formData.zigzekmargin,
          zigzak_margin_percentage: formData.marginpercentage,
          discount_price: formData.discountprice,
          discount_percentage: formData.discount_percentage,
          aadhar_number: formData.aadharNumber,
          pancard_number: formData.pancardNumber,
          aadharcard_front_image: formData.aadharImageFrontSide,
          aadharcard_back_image: formData.aadharImageBackSide,
          pancard_image: formData.panCardImage,
          bank_account_holder_name: formData.accountHolderName,
          bank_account_number: formData.bankAccountNumber,
          ifsc_code: formData.ifscCodeNumber,
          bank_name: formData.bankName,
          userRole: formData.userRole,
        };
        if (dataEdit) {
          param.astrologerId = data.astrologerId;
        }
        dispatch(setLoadingTrue());
        if (dataEdit) {
          postEditAstrologerDetails(param)
            .then((response) => {
              const event = new CustomEvent("subTabEventListner", {
                detail: "/Approveded",
              });
              window.dispatchEvent(event);
              navigate("/Approveded");
              dispatch(setLoadingFalse());
            })
            .catch((err) => {
              dispatch(setLoadingFalse());
            });
        } else {
          addNewAstrologerApiCall(param)
            .then((response) => {
              const event = new CustomEvent("subTabEventListner", {
                detail: "/Approveded",
              });
              window.dispatchEvent(event);
              navigate("/Approveded");
              dispatch(setLoadingFalse());
            })
            .catch((err) => {
              dispatch(setLoadingFalse());
            });
        }
      }
    }
  };

  const handleDownload = async (url, filename) => {
    try {
      const fileUrl = url;
      download(fileUrl, filename);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <div
      style={{
        padding: 20,

        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={`Astrologers / ${dataEdit ? "Edit" : "Add"} Astrologer`} />
      <div
        style={{
          display: "flex",
          borderRadius: "10px",
          padding: "10px",
          alignItems: "center",
          background: Colors.mainDarkColor,
          justifyContent: "space-between",
        }}
      >
        <div className="fontSize24" style={{ fontWeight: 400 }}>
          {`${dataEdit ? "Edit" : "Add"} Astrologer`}
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <div
            onClick={() => onClickDiscardAstrologer()}
            className="fontSize16"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 500,
              border: "1px solid #ffffff10",
              borderRadius: "50px",
              padding: "4px 10px 4px 10px",
              cursor: "pointer",
            }}
          >
            Discard
          </div>
          <div
            className="fontSize16"
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 500,
              border: "1px solid #ffffff10",
              background: "#FFF",
              color: "#1E1E25",
              borderRadius: "50px",
              padding: "4px 10px 4px 10px",
            }}
            onClick={() => onClickSave()}
          >
            Save
          </div>
        </div>
      </div>
      <div
        style={{
          maxHeight: window.innerHeight - 150,
          paddingBottom: "20px",
          marginTop: "20px",
        }}
        className="scrollable-container"
      >
        <div className="add-inventory-container">
          <div style={{}}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                fontSize: "1rem",
              }}
            >
              <div
                style={{ marginTop: "20px", background: "#25252D" }}
                className="add-inventory-main-div-section"
              >
                <div className="">
                  <div style={{}} className="fontSize16">
                    First name
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.firstName
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      maxLength={30}
                      className="modal-dropdown"
                      type="text"
                      placeholder="eg. Rudraksha"
                      value={formData.firstName}
                      onChange={(e) => handleInputChange(e, "firstName")}
                    />
                  </div>
                </div>
                <div style={{}} className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Last name
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.lastName
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      maxLength={30}
                      className="modal-dropdown"
                      type="text"
                      placeholder="eg. Maynk"
                      value={formData.lastName}
                      onChange={(e) => handleInputChange(e, "lastName")}
                    />
                  </div>
                </div>
                <div style={{}} className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Email
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.email
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="eg. maynk@gmail.com"
                      value={formData.email}
                      onChange={(e) => handleInputChange(e, "email")}
                      onBlur={() => onApiCallExistingMobileNumber("Email")}
                    />
                  </div>
                </div>
                {emailExisting && (
                  <div
                    style={{
                      display: "flex",
                      width: "40vh",
                      marginLeft: 5,
                      color: "#FF5733",
                      fontSize: "13px",
                    }}
                  >
                    This email has already registered
                  </div>
                )}

                <div style={{}} className="modal-second-div-title">
                  <div className="fontSize16">Country code</div>

                  <div style={{ display: "flex", gap: "0.7rem" }}>
                    <div
                      style={{ zIndex: 1, flex: 0.1 }}
                      className="modal-from-to-div"
                    >
                      <CustomDropdown
                        customLabelStyle={{ fontSize: "0.7rem" }}
                        dropDownLabel={"+91"}
                        items={["+91", "+97", "+99"]}
                        onSelect={(value) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            mobileCode: value,
                          }));
                        }}
                      />
                    </div>
                    <input
                      style={{
                        flex: 1,
                        border: formErrors.mobileNumber
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-second-dropdown"
                      type="text"
                      maxLength={10}
                      placeholder="Enter 10 digit number"
                      value={formData.mobileNumber}
                      onChange={(e) => handleInputChange(e, "mobileNumber")}
                      onBlur={() => onApiCallExistingMobileNumber("Mobile")}
                    />
                  </div>
                  {mobileExisting && (
                    <div
                      style={{
                        display: "flex",
                        width: "40vh",
                        marginLeft: 5,
                        color: "#FF5733",
                        fontSize: "13px",
                      }}
                    >
                      This mobile number has already registered
                    </div>
                  )}
                </div>

                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Profile images
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      alignSelf: "stretch",
                      borderRadius: "16px",
                      border: formErrors.productImage
                        ? "1px dashed red"
                        : "1px dashed rgba(255, 255, 255, 0.2)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "0.7rem 0.7rem",
                      gap: "0.5rem",
                      color: "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    <input
                      type="file"
                      name="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileProductInputRef}
                      onChange={handleImageChangeProduct}
                    />
                    <div style={{ position: "relative" }}>
                      <img
                        style={{
                          position: "relative",
                          width: formData.productImage == "" ? "7rem" : "10rem",
                          height:
                            formData.productImage == "" ? "7rem" : "10rem",
                          cursor: "pointer",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        alt=""
                        src={
                          formData.productImage
                            ? formData.productImage
                            : Icons.ic_upload_photo
                        }
                        onClick={() => {
                          fileProductInputRef.current.click();
                        }}
                      />
                      {formData.productImage != "" && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "1px",
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            gap: "15px",
                          }}
                        >
                          <img
                            style={{
                              height: "20px",
                              width: "35px",
                              cursor: "pointer",
                              objectFit: "cover",
                            }}
                            onClick={() =>
                              handleDownload(
                                formData.productImage,
                                "profile-pic.png"
                              )
                            }
                            alt=""
                            src={Icons.ic_download}
                          />
                        </div>
                      )}
                    </div>
                    {formData.productImage == "" && (
                      <div
                        style={{ color: "#FFFFFF80", textAlign: "center" }}
                        className="fontSize16"
                      >
                        Upload image{<br />}
                        <span style={{ fontSize: "0.7rem" }}>
                          (120px x 120px)
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{ background: "#25252D" }}
                className="add-inventory-main-div-section"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="fontSize24" style={{ fontWeight: 700 }}>
                    About astrologer
                  </div>
                </div>
                <div className="">
                  <div style={{ marginTop: 5 }} className="fontSize16">
                    Display Name
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.displayname
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      maxLength={30}
                      className="modal-dropdown"
                      type="text"
                      placeholder="Display Name"
                      value={formData.displayname}
                      onChange={(e) => handleInputChange(e, "displayname")}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", gap: "20px", zIndex: 1 }}>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Astrologer role
                    </div>
                    <div
                      style={{
                        zIndex: 1,
                        padding: "2.5px 10px 2.5px 10px",
                        border: formErrors.userRole
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-from-to-div"
                    >
                      <CustomDropdown
                        customLabelStyle={{
                          color: formData?.userRole ? "#FFF" : "#FFFFFF33",
                          fontSize: "0.75rem",
                          fontWeight: "normal",
                          // fontWeight:0
                        }}
                        dropDownLabel={
                          formData?.userRole == ""
                            ? "Select Astrologer Role"
                            : formData?.userRole
                        }
                        items={userExpertiesRole}
                        onSelect={(value) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            userRole: value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Gender
                    </div>
                    <div
                      style={{
                        zIndex: 1,
                        padding: "2.5px 10px 2.5px 10px",

                        border: formErrors.gender
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-from-to-div"
                    >
                      <CustomDropdown
                        customLabelStyle={{
                          color: formData?.gender ? "#FFF" : "#FFFFFF33",
                          fontSize: "0.75rem",
                          fontWeight: "normal",
                        }}
                        dropDownLabel={
                          formData?.gender == ""
                            ? "Select gender"
                            : formData?.gender
                        }
                        items={["Male", "Female"]}
                        onSelect={(value) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            gender: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Experience
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        border: formErrors.experience
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        maxLength={2}
                        className="modal-dropdown"
                        type="text"
                        placeholder="Experience in years"
                        value={formData.experience}
                        onChange={(e) => handleInputChange(e, "experience")}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: "flex", marginTop: "10px", gap: "20px" }}
                >
                  <div style={{}} className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Languages
                    </div>

                    <div
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {formData?.Languages.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => handleLanguageSelected(index)}
                            style={{
                              background: item.selected
                                ? "#FFF"
                                : "transparent",
                              margin: "5px",
                              cursor: "pointer",
                              border: "1px solid #ffffff10",
                              borderRadius: "15px",
                              padding: "7px 10px 7px 10px",
                              color: item.selected && "#000",
                            }}
                            className="expertice-list-font"
                          >
                            {item?.LanguageName}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: "flex", marginTop: "10px", gap: "20px" }}
                >
                  <div style={{}} className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Expertise
                    </div>

                    <div
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {formData.expertise.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => handleItemClick(index)}
                            style={{
                              background: item.selected
                                ? "#FFF"
                                : "transparent",
                              margin: "5px",
                              cursor: "pointer",
                              border: "1px solid #ffffff10",
                              borderRadius: "15px",
                              padding: "7px 10px 7px 10px",
                              color: item.selected && "#000",
                            }}
                            className="expertice-list-font"
                          >
                            {item.ExpertiseName}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Bio
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      borderRadius: "10px",
                      padding: "10px",

                      border: formErrors.bio
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <textarea
                      maxLength={150}
                      className="modal-dropdown"
                      type="text"
                      style={{ height: "5rem", flex: 1, paddingLeft: "0rem" }}
                      placeholder="About bio"
                      value={formData.bio}
                      onChange={(e) => handleInputChange(e, "bio")}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{ background: "#25252D" }}
                className="add-inventory-main-div-section"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="fontSize24" style={{ fontWeight: 700 }}>
                    Documents
                  </div>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <div
                    style={{
                      flex: 1,
                      border: "1px solid #ffffff10",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="modal-second-div-title">
                      <div style={{}} className="fontSize16">
                        Aadhar Number
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          border: formErrors.aadharNumber
                            ? "1px solid red"
                            : "1px solid rgba(255, 255, 255, 0.2)",
                        }}
                        className="modal-coupon-code-type"
                      >
                        <input
                          maxLength={12}
                          className="modal-dropdown"
                          type="text"
                          placeholder="Enter Aadhar card number"
                          value={formData.aadharNumber}
                          onChange={(e) => handleInputChange(e, "aadharNumber")}
                        />
                      </div>
                    </div>
                    <div className="modal-second-div-title">
                      <div style={{}} className="fontSize16">
                        Aadhar card image
                      </div>
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <div
                          style={{
                            marginTop: "5px",
                            flex: 1,
                            alignSelf: "stretch",
                            borderRadius: "16px",
                            border: formErrors.aadharImageFrontSide
                              ? "1px dashed red"
                              : "1px dashed rgba(255, 255, 255, 0.2)",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "6rem",
                            color: "rgba(255, 255, 255, 0.5)",
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            ref={fileAadharFrontInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => handleImageChangeAadharFront(e)}
                          />
                          {formData.aadharImageFrontSide != "" ? (
                            <div style={{ position: "relative" }}>
                              <img
                                style={{
                                  height: "6rem",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  objectFit: "contain",
                                  width: formData.aadharImageFrontSide
                                    ? "auto"
                                    : "3rem",
                                }}
                                alt=""
                                src={
                                  formData.aadharImageFrontSide
                                    ? formData.aadharImageFrontSide
                                    : Icons.ic_upload_photo
                                }
                                onClick={() =>
                                  fileAadharFrontInputRef.current.click()
                                }
                              />
                              {formData.aadharImageFrontSide != "" && (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    gap: "15px",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "20px",
                                      width: "35px",
                                      cursor: "pointer",
                                      objectFit: "cover",
                                    }}
                                    onClick={() =>
                                      handleDownload(
                                        formData.aadharImageFrontSide,
                                        "aadhar-card.png"
                                      )
                                    }
                                    alt=""
                                    src={Icons.ic_download}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <img
                              style={{
                                height: "6rem",
                                borderRadius: "10px",
                                cursor: "pointer",
                                objectFit: "contain",
                                width: formData.aadharImageFrontSide
                                  ? "auto"
                                  : "3rem",
                              }}
                              alt=""
                              src={
                                formData.aadharImageFrontSide
                                  ? formData.aadharImageFrontSide
                                  : Icons.ic_upload_photo
                              }
                              onClick={() =>
                                fileAadharFrontInputRef.current.click()
                              }
                            />
                          )}
                          {formData.aadharImageFrontSide ? (
                            <></>
                          ) : (
                            <div className="upload-image-text">Front side</div>
                          )}
                        </div>

                        <div
                          style={{
                            flex: 1,
                            marginTop: "5px",
                            alignSelf: "stretch",
                            borderRadius: "16px",
                            border: formErrors.aadharImageBackSide
                              ? "1px dashed red"
                              : "1px dashed rgba(255, 255, 255, 0.2)",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "6rem",
                            color: "rgba(255, 255, 255, 0.5)",
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            ref={fileAadharBackInputRef}
                            style={{ display: "none" }}
                            onChange={handleImageChangeAadharBack}
                          />
                          {formData.aadharImageBackSide == "" ? (
                            <img
                              style={{
                                height: "6rem",
                                width: formData.aadharImageBackSide
                                  ? "auto"
                                  : "3rem",
                                borderRadius: "10px",
                                cursor: "pointer",
                                objectFit: "contain",
                              }}
                              alt=""
                              src={
                                formData.aadharImageBackSide
                                  ? formData.aadharImageBackSide
                                  : Icons.ic_upload_photo
                              }
                              onClick={() =>
                                fileAadharBackInputRef.current.click()
                              }
                            />
                          ) : (
                            <div style={{ position: "relative" }}>
                              <img
                                style={{
                                  height: "6rem",
                                  width: formData.aadharImageBackSide
                                    ? "auto"
                                    : "3rem",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  objectFit: "contain",
                                }}
                                alt=""
                                src={
                                  formData.aadharImageBackSide
                                    ? formData.aadharImageBackSide
                                    : Icons.ic_upload_photo
                                }
                                onClick={() =>
                                  fileAadharBackInputRef.current.click()
                                }
                              />

                              {formData.aadharImageBackSide != "" && (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    gap: "15px",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "20px",
                                      width: "35px",
                                      cursor: "pointer",
                                      objectFit: "cover",
                                    }}
                                    onClick={() =>
                                      handleDownload(
                                        formData.aadharImageBackSide,
                                        "aadhar-card.png"
                                      )
                                    }
                                    alt=""
                                    src={Icons.ic_download}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          {formData.aadharImageBackSide ? (
                            <></>
                          ) : (
                            <div className="upload-image-text">Back side</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      border: "1px solid #ffffff10",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="modal-second-div-title">
                      <div style={{}} className="fontSize16">
                        PAN card number
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          border: formErrors.pancardNumber
                            ? "1px solid red"
                            : "1px solid rgba(255, 255, 255, 0.2)",
                        }}
                        className="modal-coupon-code-type"
                      >
                        <input
                          className="modal-dropdown"
                          type="text"
                          maxLength={10}
                          placeholder="Enter PAN card number"
                          value={formData.pancardNumber}
                          onChange={(e) =>
                            handleInputChange(e, "pancardNumber")
                          }
                        />
                      </div>
                    </div>
                    <div className="modal-second-div-title">
                      <div style={{}} className="fontSize16">
                        PAN card image
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          alignSelf: "stretch",
                          borderRadius: "16px",
                          border: formErrors.panCardImage
                            ? "1px dashed red"
                            : "1px dashed rgba(255, 255, 255, 0.2)",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "6rem",
                          color: "rgba(255, 255, 255, 0.5)",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileAadharPancardInputRef}
                          style={{ display: "none" }}
                          onChange={handleImageChangePancard}
                        />
                        {formData.panCardImage == "" ? (
                          <img
                            style={{
                              height: "6rem",
                              width: formData.panCardImage ? "auto" : "3rem",
                              borderRadius: "10px",
                              cursor: "pointer",
                              objectFit: "contain",
                            }}
                            onClick={() =>
                              fileAadharPancardInputRef.current.click()
                            }
                            alt=""
                            src={
                              formData.panCardImage
                                ? formData.panCardImage
                                : Icons.ic_upload_photo
                            }
                          />
                        ) : (
                          <div style={{ position: "relative" }}>
                            <img
                              style={{
                                height: "6rem",
                                width: formData.panCardImage ? "auto" : "3rem",
                                borderRadius: "10px",
                                cursor: "pointer",
                                objectFit: "contain",
                              }}
                              onClick={() =>
                                fileAadharPancardInputRef.current.click()
                              }
                              alt=""
                              src={
                                formData.panCardImage
                                  ? formData.panCardImage
                                  : Icons.ic_upload_photo
                              }
                            />
                            {formData.panCardImage != "" && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                  gap: "15px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "20px",
                                    width: "35px",
                                    cursor: "pointer",
                                    objectFit: "cover",
                                  }}
                                  onClick={() =>
                                    handleDownload(
                                      formData.panCardImage,
                                      "pan-card.png"
                                    )
                                  }
                                  alt=""
                                  src={Icons.ic_download}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {formData.panCardImage ? (
                          <></>
                        ) : (
                          <div className="upload-image-text">Upload image</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ background: "#25252D" }}
                className="add-inventory-main-div-section"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="fontSize24" style={{ fontWeight: 700 }}>
                    Pricing
                  </div>
                </div>

                <div style={{ display: "flex", gap: "20px" }}>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Price per minute
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        border: formErrors.priceperminut
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        className="modal-dropdown"
                        type="text"
                        placeholder="₹"
                        value={"₹" + formData.priceperminut}
                        onChange={(e) => handleInputChange(e, "priceperminut")}
                      />
                    </div>
                  </div>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Astrologer's charges
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        background: "#FFFFFF1A",
                        border: formErrors.astrologercharge
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        className="modal-dropdown"
                        type="text"
                        disabled
                        placeholder="₹"
                        value={"₹" + formData.astrologercharge}
                        onChange={(e) =>
                          handleInputChange(e, "astrologercharge")
                        }
                      />
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", gap: "20px" }}>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Discount price
                    </div>
                    <div
                      style={{
                        marginTop: "5px",

                        border: formErrors.discountprice
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        className="modal-dropdown"
                        type="text"
                        placeholder="₹"
                        value={"₹" + formData.discountprice}
                        onChange={(e) => handleInputChange(e, "discountprice")}
                      />
                    </div>
                  </div>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Discount in %
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        background: "#FFFFFF1A",
                        border: formErrors.discount_percentage
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        disabled
                        className="modal-dropdown"
                        type="text"
                        placeholder="%"
                        value={formData?.discount_percentage + "%"}
                        onChange={(e) =>
                          handleInputChange(e, "discount_percentage")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Zigzek margin
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        background:
                          formData.discountprice <= 0
                            ? "#FFFFFF1A"
                            : "transparent",
                        border: formErrors.zigzekmargin
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        disabled={formData.discountprice <= 0}
                        className="modal-dropdown"
                        type="text"
                        placeholder="₹"
                        value={"₹" + formData.zigzekmargin}
                        onChange={(e) => handleInputChange(e, "zigzekmargin")}
                      />
                    </div>
                  </div>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Margin in %
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        background: "#FFFFFF1A",

                        border: formErrors.marginpercentage
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        className="modal-dropdown"
                        disabled
                        type="text"
                        placeholder="%"
                        value={formData.marginpercentage + "%"}
                        onChange={(e) =>
                          handleInputChange(e, "marginpercentage")
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{ display: "flex", marginTop: "10px", gap: "20px" }}
                >
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Cost per item
                    </div>
                    <div
                      style={{ marginTop: "5px" }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        className="modal-dropdown"
                        type="text"
                        placeholder="₹"
                        value={formData.cost_per_item}
                        onChange={(e) => handleInputChange(e, "cost_per_item")}
                      />
                    </div>
                  </div>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Profit
                    </div>
                    <div
                      style={{ marginTop: "5px" }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        className="modal-dropdown"
                        type="text"
                        placeholder="₹"
                        value={formData.profit}
                        onChange={(e) => handleInputChange(e, "profit")}
                      />
                    </div>
                  </div>

                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Margin
                    </div>
                    <div
                      style={{ marginTop: "5px" }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        className="modal-dropdown"
                        type="text"
                        placeholder=""
                        value={formData.margin}
                        onChange={(e) => handleInputChange(e, "margin")}
                      />
                      <div style={{ color: "rgba(255, 255, 255, 0.2)" }}>
                        {"%"}
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div
                style={{ background: "#25252D" }}
                className="add-inventory-main-div-section"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="fontSize24" style={{ fontWeight: 700 }}>
                    Bank details
                  </div>
                </div>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Account holder name
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.accountHolderName
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="Enter name"
                      value={formData.accountHolderName}
                      onChange={(e) =>
                        handleInputChange(e, "accountHolderName")
                      }
                    />
                  </div>
                </div>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Bank account number
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.bankAccountNumber
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="Enter account number"
                      value={formData.bankAccountNumber}
                      onChange={(e) =>
                        handleInputChange(e, "bankAccountNumber")
                      }
                    />
                  </div>
                </div>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    IFSC code
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.ifscCodeNumber
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="IFSC code"
                      value={formData.ifscCodeNumber}
                      onChange={(e) => handleInputChange(e, "ifscCodeNumber")}
                    />
                  </div>
                </div>
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Bank name
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.bankName
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="Enter bank name"
                      value={formData.bankName}
                      onChange={(e) => handleInputChange(e, "bankName")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAstrologer;
